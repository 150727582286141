export default {
  methods: {
    hasScope(scope) {
      if (this.$store.state.principal === null) {
        return false;
      }

      return this.$store.state.principal.scopes.includes(scope);
    },

    isAdmin() {
      return this.$store.state.principal.admin;
    },

    isModerator() {
      return this.$store.state.principal.admin || this.$store.state.principal.moderator;
    },

    isModeratorOf(channels, channel) {
      if (this.$store.state.principal.admin) {
        return true;
      }
      if (!this.$store.state.principal.moderator) {
        return false;
      }

      return channels.some((c) => channel.uuid === c.uuid && c.role === 'moderator');
    },
  },
};
