import axios from 'axios';

export default {
  async all() {
    return axios.get('/api/v1/users?all=1').then((response) => response.data);
  },

  async list(page, terms) {
    const query = [];

    if (terms !== undefined) {
      query.push(`q=${encodeURI(terms)}`);
    }

    return axios.get(`/api/v1/users?page=${page}&${query.join('&')}`).then((response) => response.data);
  },

  async create(email, name, writerChannels, moderatorChannels) {
    const body = {
      email,
      name,
      channels: {
        writers: writerChannels,
        moderators: moderatorChannels,
      },
    };

    return axios.post('/api/v1/users', body);
  },

  async get(uuid) {
    return axios.get(`/api/v1/users/${uuid}`).then((response) => response.data);
  },

  async update(uuid, name, writerChannels, moderatorChannels) {
    const body = {
      name,
      channels: {
        writers: writerChannels,
        moderators: moderatorChannels,
      },
    };

    return axios.put(`/api/v1/users/${uuid}`, body);
  },

  async delete(uuid) {
    return axios.delete(`/api/v1/users/${uuid}`);
  },
};
