<template lang="pug">
Main
  h1.title {{ $t("titles.channels_edit") }}
  h2.subtitle {{ $t("channels.subtitle_edit") }}

  .box
    .is-pulled-right
      b-switch(size='is-small', v-model='form.moderated') {{ $t("channels.moderated") }}

    .form(v-if='newForm || isLoaded')
      .columns.is-vcentered
        .column.is-1.has-text-centered
          .avatar-upload(@click='launchPictureUpload')
            b-upload.is-hidden(v-model='form.picture', ref='pictureUpload')
            .image.is-square
              img.is-rounded(
                v-if='channel.picture',
                :src='channel.picture',
                ref='picture'
              )
              img.is-rounded(v-else, :src='assets.placeholder', ref='picture')
            b-icon.has-text-white(size='is-medium', icon='upload')

          b-button.mt-1(
            v-if='channel.picture',
            icon-right='delete',
            type='is-danger',
            size='is-small',
            @click='deletePicture'
          )

        .column.is-11
          b-field(
            :label='$t("channels.name")',
            :type='fieldClass($v.form.name)'
          )
            b-input(
              ref='name',
              type='text',
              placeholder='Bureau des étudiants',
              :maxlength='config.feed.channels.name_max_length',
              v-model='$v.form.name.$model',
              @keyup.native.enter='update'
            )

      h3.is-size-5.mb-3 {{ $t("channels.visibilities") }}

      b-field
        b-checkbox-button(
          v-model='form.visibilities',
          size='is-small',
          type='is-info',
          native-value='public'
        ) {{ $t("channels.public") }}
        b-checkbox-button(
          v-model='form.visibilities',
          size='is-small',
          type='is-info',
          native-value='private'
        ) {{ $t("channels.private") }}
        b-checkbox-button(
          v-model='form.visibilities',
          size='is-small',
          type='is-info',
          native-value='audience'
        ) {{ $t("channels.audience") }}

      b-notification(
        v-if='form.visibilities.includes("private") || form.visibilities.includes("public")',
        type='is-warning is-light',
        :closable='false'
      ) {{ audienceWarning }}

      .field(v-if='form.visibilities.includes("audience")')
        CustomSelect(
          v-if='audiences && audiences.length > 0',
          v-model='form.audiences',
          :multiple='true',
          :options='audiences',
          track-by='slug',
          label='name',
          :placeholder='$t("channels.restrict_audiences")'
        )
        p.is-size-7.has-text-grey(v-else) {{ $t("channels.no_audiences") }}

      h3.is-size-5.mb-3 {{ $t("channels.permissions") }}

      b-field(:label='$t("channels.writers")')
        CustomSelect(
          v-if='users',
          v-model='form.writers',
          @input='setWriters',
          :multiple='true',
          :options='users',
          track-by='uuid',
          label='name',
          :placeholder='$t("channels.restrict_users")'
        )

      b-field(:label='$t("channels.moderators")')
        CustomSelect(
          v-if='users',
          v-model='form.moderators',
          @input='setModerators',
          :multiple='true',
          :options='users',
          track-by='uuid',
          label='name',
          :placeholder='$t("channels.restrict_moderators")'
        )

      .field
        p.control
          b-button.is-info.is-light(
            :loading='isSubmitting',
            @click='update',
            :disabled='$v.$invalid'
          )
            template(v-if='newForm') {{ $t("channels.create") }}
            template(v-else) {{ $t("channels.update") }}

    div(v-else)
      b-skeleton(width='200px')
      b-skeleton(size='is-large', width='100%')

      .my-3: b-skeleton.mb-3(size='is-large', width='300px')

      .level.mb-1(v-for='n in 2')
        .icon.is-medium.mr-2: b-skeleton(size='is-medium')
        b-skeleton(size='is-medium', width='200px')

      .mt-2: b-skeleton(size='is-large', width='200px')
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';

import UsersService from '@/services/UsersService';
import ChannelsService from '@/services/ChannelsService';
import AudiencesService from '@/services/AudiencesService';

import Main from '@/components/Main.vue';
import Placeholder from '@/images/placeholder-image.png';
import CustomSelect from '@/components/partials/CustomSelect.vue';
import { required } from 'vuelidate/lib/validators';

export default {
  components: {
    Main,
    CustomSelect,
  },

  props: {
    newForm: Boolean,
  },

  data: () => ({
    assets: {
      placeholder: Placeholder,
    },
    isLoaded: false,
    isSubmitting: false,
    users: undefined,
    audiences: undefined,
    channel: {
      picture: undefined,
    },
    form: {
      name: '',
      visibilities: [],
      audiences: [],
      picture: undefined,
      removePicture: false,
      moderated: false,
      writers: [],
      moderators: [],
    },
  }),

  computed: {
    audienceWarning() {
      if (this.form.visibilities.includes('private') && this.form.visibilities.includes('public')) {
        return this.$t('channels.audience_warning_all');
      }
      if (this.form.visibilities.includes('private')) {
        return this.$t('channels.audience_warning_private');
      }
      if (this.form.visibilities.includes('public')) {
        return this.$t('channels.audience_warning_public');
      }

      return '';
    },
    ...mapState({
      config: 'config',
    }),
  },

  validations: {
    form: {
      name: { required },
      audiences: (value) => !value.visibilities.includes('audience') || value.audiences.length > 0,
      visibilities: { required },
    },
  },

  watch: {
    'form.picture': function pictureWatcher(value) {
      if (value === undefined) {
        return;
      }

      this.form.removePicture = false;

      const reader = new FileReader();

      reader.onload = (e) => {
        this.channel.picture = e.target.result;
        this.$refs.picture.setAttribute('src', e.target.result);
      };

      reader.readAsDataURL(value);
    },
  },

  async mounted() {
    UsersService.all().then((users) => {
      this.users = users.data;
    });

    if (!this.newForm) {
      AudiencesService.list().then((audiences) => {
        this.audiences = audiences;

        ChannelsService.get(this.$route.params.uuid).then((channel) => {
          this.$store.commit('setTitle', { title: 'head.channel', params: { channel: channel.name } });

          this.form.name = channel.name;
          this.form.moderated = channel.moderated;
          this.form.writers = channel.writers;
          this.form.moderators = channel.moderators;
          this.channel.picture = channel.picture;
          this.isLoaded = true;

          if (channel.public) {
            this.form.visibilities.push('public');
          }
          if (channel.private) {
            this.form.visibilities.push('private');
          }
          if (channel.audience) {
            this.form.visibilities.push('audience');
          }

          this.form.audiences = channel.audiences.map((selectedAudience) => {
            const audience = selectedAudience;
            const source = this.audiences.find((a) => a.slug === selectedAudience.slug);

            if (source !== undefined) {
              audience.name = source.name;
            }

            return audience;
          });

          this.$nextTick(() => {
            this.$refs.name.focus();
          });
        });
      });
    } else {
      this.$store.commit('setTitle', { title: 'head.channel_new' });

      this.$refs.name.focus();

      AudiencesService.list().then((audiences) => {
        this.audiences = audiences;
      });
    }
  },

  methods: {
    fieldClass(field) {
      if (field.$dirty && field.$invalid) {
        return 'is-danger';
      }

      return '';
    },

    launchPictureUpload() {
      this.$refs.pictureUpload.$el.click();
    },

    deletePicture() {
      this.$refs.picture.setAttribute('src', this.assets.placeholder);
      this.channel.picture = undefined;
      this.form.picture = undefined;
      this.form.removePicture = true;
    },

    setWriters() {
      this.form.writers.forEach((user) => {
        this.form.moderators = _.reject(this.form.moderators, (moderator) => moderator.uuid === user.uuid);
      });
    },

    setModerators() {
      this.form.moderators.forEach((user) => {
        this.form.writers = _.reject(this.form.writers, (writer) => writer.uuid === user.uuid);
      });
    },

    update() {
      if (!this.$v.$invalid) {
        this.isSubmitting = true;

        const writers = this.form.writers.map((user) => user.uuid);
        const moderators = this.form.moderators.map((user) => user.uuid);

        if (this.newForm) {
          ChannelsService.create(this.form.name, this.form.visibilities, this.form.audiences, this.form.picture, this.form.moderated, writers, moderators)
            .then((_response) => {
              this.$flash.add('success', 'channels.new_success');
              this.$router.push({ name: 'channels.list' });
            })
            .catch((error) => {
              this.isSubmitting = false;
              this.$flash.add('danger', 'channels.new_error', error.response.data.message);
            });
        } else {
          ChannelsService.update(
            this.$route.params.uuid,
            this.form.name,
            this.form.visibilities,
            this.form.audiences,
            this.form.picture,
            this.form.removePicture,
            this.form.moderated,
            writers,
            moderators,
          )
            .then((_response) => {
              this.$flash.add('success', 'channels.edit_success');
              this.$router.push({ name: 'channels.list' });
            })
            .catch((error) => {
              this.isSubmitting = false;
              this.$flash.add('danger', 'channels.edit_error', error.response.data.message);
            });
        }
      }
    },
  },
};
</script>

<style lang="scss">
.file-cta {
  height: auto;
}

.avatar-upload {
  position: relative;

  span.icon {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    span.icon {
      opacity: 1;
    }
  }
}
</style>
