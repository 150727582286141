<template lang="pug">
Main
  h1.title {{ $t("titles.verify") }}
  h2.subtitle {{ $t("verify.subtitle") }}

  .box
    b-field(
      :label='$t("signup.password")',
      :type='fieldClass($v.form.password)'
    )
      b-input(
        type='password',
        placeholder='********',
        v-model='$v.form.password.$model',
        @keyup.native.enter='verify'
      )

    password(
      v-model='form.password',
      :strengthMeterOnly='true',
      :badge='false',
      :secureLength='12'
    )

    b-field(
      :label='$t("signup.password_confirmation")',
      :type='$v.form.passwordConfirmation'
    )
      b-input(
        type='password',
        placeholder='********',
        v-model='$v.form.passwordConfirmation.$model',
        @keyup.native.enter='verify'
      )

    b-field
      p.control
        b-button.is-info.is-light(
          @click='verify',
          :loading='isSubmitting',
          :disabled='$v.$invalid'
        ) {{ $t("titles.verify") }}
</template>

<script>
import SessionsService from '@/services/SessionService';
import { required, sameAs } from 'vuelidate/lib/validators';

import Main from '@/components/Main.vue';
import Password from 'vue-password-strength-meter';

export default {
  components: {
    Main,
    Password,
  },

  data: () => ({
    isSubmitting: false,
    form: {
      password: '',
      passwordConfirmation: '',
    },
  }),

  validations: {
    form: {
      password: { required },
      passwordConfirmation: {
        sameAsPassword: sameAs('password'),
      },
    },
  },

  async mounted() {
    this.$store.commit('setTitle', { title: 'head.verify' });
  },

  methods: {
    fieldClass(field) {
      if (field.$dirty && field.$invalid) {
        return 'is-danger';
      }

      return '';
    },

    verify() {
      this.isSubmitting = true;

      const { token } = this.$route.params;

      SessionsService.verifyAccount(token, this.form.password, this.form.passwordConfirmation)
        .then((_response) => {
          this.$flash.add('success', 'errors.sessions.verify_success');
          this.$router.push({ name: 'sessions.signin' });
        })
        .catch((error) => {
          this.isSubmitting = false;
          this.$flash.add('danger', 'errors.sessions.verify_failed', error.response.data.message);
        });
    },
  },
};
</script>
