<template lang="pug">
Main
  .is-pulled-right(v-if='post')
    b-button.mr-2(
      v-if='!post.pinned && !post.deleted && $store.state.principal.admin',
      size='is-small',
      icon-left='pin',
      :loading='isPinning',
      @click='togglePin'
    ) {{ $t("posts.pin") }}
    b-button.mr-2(
      v-else-if='!post.deleted && $store.state.principal.admin',
      size='is-small',
      icon-left='pin',
      :loading='isPinning',
      @click='togglePin'
    ) {{ $t("posts.unpin") }}

    b-button(
      v-if='!post.deleted && !post.moderated && $root.isModeratorOf(channels, post.channel)',
      type='is-danger is-light',
      size='is-small',
      icon-left='delete',
      @click='deletePost'
    ) {{ $t("global.delete") }}

  h1.title
    .level: .level-left
      .level-item: span {{ $t("titles.post") }}
      .level-item(v-if='post'): b-tag(
        v-if='post.deleted',
        type='is-danger is-light'
      ) {{ $t("posts.deleted") }}
  h2.subtitle(v-if='post') {{ formatDate(post.created_at) }}

  b-notification.moderate(
    v-if='post && post.moderated && $root.isModeratorOf(channels, post.channel)',
    type='is-warning is-light',
    has-icon,
    icon='clock-outline',
    :closable='false'
  )
    p {{ $t("posts.moderate", { date: formatDate(post.moderated_until) }) }}
    .mt-2
      b-button.mr-2(
        @click='moderatePublish',
        type='is-success is-light',
        size='is-small',
        icon-left='check'
      ) {{ $t("posts.moderate_publish") }}
      b-button(
        @click='moderateDelete',
        type='is-danger is-light',
        size='is-small',
        icon-left='delete'
      ) {{ $t("posts.moderate_delete") }}

  .columns(v-if='post')
    .column
      b-notification(:closable='false')
        .level
          .level-left
            .level-item.mr-4
              figure.image.is-64x64.is-square
                img.is-rounded(
                  v-if='post.channel.picture',
                  :src='post.channel.picture'
                )
                img.is-rounded(v-else, :src='assets.imagePlaceholder')
            .level-item
              p
                b
                  router-link(
                    :to='{ name: "posts.list", params: { channel: post.channel.uuid } }',
                    exact
                  ) {{ post.channel.name }}
                br
                | {{ formatDate(post.created_at) }}
    .column
      b-notification(:closable='false')
        .level
          .level-left
            .level-item.mr-4
              figure.image.is-64x64.is-square
                img.is-rounded(:src='assets.userPlaceholder')
            .level-item
              p(v-if='post.author')
                b {{ post.author.name }}
                br
                | {{ post.author.context.name }}
              p(v-else) {{ $t("posts.deleted_user") }}

    .column.is-flex
      b-notification.is-flex-grow-1(:closable='false')
        .level
          .level-item.has-text-centered
            div
              p.heading {{ $t("stats.view") }}
              p.title {{ post.statistics.view || 0 }}
          .level-item.has-text-centered
            div
              p.heading {{ $t("stats.open") }}
              p.title {{ post.statistics.open || 0 }}
          .level-item.has-text-centered
            div
              p.heading {{ $t("stats.like") }}
              p.title {{ likes }}

  .mb-5(v-if='post')
    b-tag.mr-1.public(v-if='post.public') {{ $t("channels.public") }}
    b-tag.mr-1.private(v-if='post.private') {{ $t("channels.private") }}
    template(v-if='post.audience', v-for='audience in post.audiences')
      b-tag.mr-1(v-if='audience.enabled') {{ audience.name }}
      b-tag.mr-1(v-else, type='is-warning')
        | {{ audience.name }}
        = " "
        em ({{ $t("posts.deleted_audience") }})

  .box
    template(v-if='post')
      figure.cover.image.mb-3(v-if='post.picture')
        img(:src='post.picture')

      .content(v-html='post.content')

    template(v-else)
      b-skeleton(width='200px')
      b-skeleton(width='600px')
      b-skeleton(width='400px')
      b-skeleton(width='500px')
</template>

<script>
import UserPlaceholder from '@/images/placeholder-user.png';
import ImagePlaceholder from '@/images/placeholder-image.png';

import Main from '@/components/Main.vue';

import ChannelsService from '@/services/ChannelsService';
import PostsService from '@/services/PostsService';

const REFRESH_INTERVAL = 10 * 1000;

export default {
  components: {
    Main,
  },

  data: () => ({
    assets: {
      userPlaceholder: UserPlaceholder,
      imagePlaceholder: ImagePlaceholder,
    },
    channels: undefined,
    post: undefined,
    isPinning: false,
    interval: undefined,
  }),

  computed: {
    likes() {
      if (!this.post.reactions.like) {
        return 0;
      }

      return this.post.reactions.like.count;
    },
  },

  async mounted() {
    ChannelsService.all().then((channels) => {
      this.channels = channels.data;
    });

    this.refresh();

    this.interval = setInterval(() => {
      this.refresh();
    }, REFRESH_INTERVAL);
  },

  async beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    async refresh() {
      PostsService.get(this.$route.params.post).then((post) => {
        this.$store.commit('setTitle', { title: 'head.post', params: { channel: post.channel.name } });

        this.post = post;
      });
    },

    togglePin() {
      this.isPinning = true;

      if (this.post.pinned) {
        PostsService.unpin(this.post.uuid)
          .then((_post) => {
            this.refresh().then(() => {
              this.post.pinned = false;
              this.isPinning = false;
            });
          })
          .catch((error) => {
            this.$flash.add('danger', 'posts.pin_error', error.response.data.message);
            this.isPinning = false;
          });
      } else {
        PostsService.pin(this.post.uuid)
          .then((_post) => {
            this.refresh().then(() => {
              this.post.pinned = true;
              this.isPinning = false;
            });
          })
          .catch((error) => {
            this.$flash.add('danger', 'posts.unpin_error', error.response.data.message);
            this.isPinning = false;
          });
      }
    },

    moderatePublish() {
      PostsService.moderatePublish(this.post.uuid)
        .then(() => {
          this.$flash.add('success', 'posts.moderation_publish_success');
          this.refresh();
        })
        .catch((error) => {
          this.$flash.add('danger', 'posts.moderation_error', error.response.data.message);
        });
    },

    moderateDelete() {
      PostsService.moderateDelete(this.post.uuid)
        .then(() => {
          this.$flash.add('success', 'posts.moderation_delete_success');
          this.refresh();
        })
        .catch((error) => {
          this.$flash.add('danger', 'posts.moderation_error', error.response.data.message);
        });
    },

    deletePost() {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        hasIcon: true,
        title: this.$t('posts.delete_confirm_title'),
        message: this.$t('posts.delete_confirm_content'),
        cancelText: this.$t('global.cancel'),
        onConfirm: () => {
          PostsService.delete(this.post.uuid)
            .then((_response) => {
              this.$flash.add('success', 'posts.delete_success');
              this.refresh();
            })
            .catch(() => {
              this.$flash.add('danger', 'posts.delete_error');
            });
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cover {
  max-height: 400px;
  overflow: hidden;
  border-radius: 8px;
}

.moderate ::v-deep i.mdi,
.moderate ::v-deep i.mdi::before {
  font-size: 24px !important;
}

.tag {
  &.private,
  &.public {
    background: #0499bc;
    color: white;
  }

  em {
    font-size: 0.9em;
  }
}
</style>
