import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';
import AuthService from '@/services/AuthService';

import SignIn from '@/components/sessions/SignIn.vue';
import Verify from '@/components/sessions/Verify.vue';
import RequestReset from '@/components/sessions/RequestReset.vue';
import Reset from '@/components/sessions/Reset.vue';

import Home from '@/components/home/Home.vue';
import Account from '@/components/account/Account.vue';
import PostList from '@/components/posts/List.vue';
import PostShow from '@/components/posts/Show.vue';
import PostNew from '@/components/posts/Form.vue';
import ChannelList from '@/components/channels/List.vue';
import ChannelForm from '@/components/channels/Form.vue';
import UserList from '@/components/users/List.vue';
import UserForm from '@/components/users/Form.vue';
import ModerationList from '@/components/moderation/List.vue';

import ErrorNotFound from '@/components/errors/NotFound.vue';

import SessionService from './services/SessionService';

Vue.use(VueRouter);

const routes = [
  { name: 'sessions.signin', path: '/-/signin', component: SignIn },
  { name: 'sessions.verify', path: '/-/verify/:token', component: Verify },
  { name: 'sessions.reset', path: '/-/reset', component: RequestReset },
  { name: 'sessions.doreset', path: '/-/reset/:token', component: Reset },
  {
    name: 'home',
    path: '/',
    component: Home,
    meta: { private: true },
  },
  {
    name: 'account',
    path: '/account',
    component: Account,
    meta: { private: true },
  },
  {
    name: 'posts.list',
    path: '/posts/:channel?',
    component: PostList,
    meta: { private: true },
  },
  {
    name: 'posts.new',
    path: '/posts/:channel/new',
    component: PostNew,
    meta: { private: true },
  },
  {
    name: 'posts.show',
    path: '/posts/:channel/:post',
    component: PostShow,
    meta: { private: true },
  },
  {
    name: 'channels.list',
    path: '/channels',
    component: ChannelList,
    meta: { private: true, admin: true },
  },
  {
    name: 'channels.new',
    path: '/channels/new',
    component: ChannelForm,
    props: { newForm: true },
    meta: { private: true, admin: true },
  },
  {
    name: 'channels.edit',
    path: '/channels/:uuid',
    component: ChannelForm,
    props: { newForm: false },
    meta: { private: true, admin: true },
  },
  {
    name: 'users.list',
    path: '/users',
    component: UserList,
    meta: { private: true, admin: true },
  },
  {
    name: 'users.new',
    path: '/users/new',
    component: UserForm,
    props: { newForm: true },
    meta: { private: true, admin: true },
  },
  {
    name: 'users.edit',
    path: '/users/:uuid',
    component: UserForm,
    props: { newForm: false },
    meta: { private: true, admin: true },
  },
  {
    name: 'moderation.list',
    path: '/moderation',
    component: ModerationList,
    meta: { private: true, moderator: true },
  },
  {
    name: 'moderation.recent',
    path: '/moderation/recent',
    component: ModerationList,
    meta: { private: true, moderator: true },
  },
  {
    name: 'errors.notfound',
    path: '/errors/404',
    component: ErrorNotFound,
    alias: '*',
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(async (to, _from, next) => {
  if (!router.keepFlashes) {
    store.commit('clearFlash');
  }

  router.keepFlashes = false;

  if (to.path.startsWith('/-/auth/')) {
    return next();
  }

  return AuthService.userinfo().then(
    () => {
      if (to.name === 'home' || to.name === 'sessions.verify' || to.name === 'sessions.signin') {
        return next({ name: 'posts.list' });
      }
      if (to.matched.some((record) => record.meta.admin) && !store.state.principal.admin) {
        return next({ name: 'posts.list' });
      }
      if (to.matched.some((record) => record.meta.moderator) && !store.state.principal.moderator && !store.state.principal.admin) {
        return next({ name: 'posts.list' });
      }

      return next();
    },
    () => {
      AuthService.refresh()
        .then((response) => {
          SessionService.setLocalStorage(store, response.data);

          AuthService.userinfo().then(
            () => {
              if (to.name === 'home' || to.name === 'sessions.verify' || to.name === 'sessions.signin') {
                return next({ name: 'posts.list' });
              }
              if (to.matched.some((record) => record.meta.admin) && !store.state.principal.admin) {
                return next({ name: 'posts.list' });
              }
              if (to.matched.some((record) => record.meta.moderator) && !store.state.principal.moderator && !store.state.principal.admin) {
                return next({ name: 'posts.list' });
              }

              return next();
            },
            () => {
              store.commit('deletePrincipal');

              if (to.matched.some((record) => record.meta.private)) {
                return next({ name: 'sessions.signin' });
              }

              return next();
            },
          );

          return next();
        })
        .catch(() => {
          store.commit('deletePrincipal');

          if (to.matched.some((record) => record.meta.private)) {
            return next({ name: 'sessions.signin' });
          }

          return next();
        });
    },
  );
});

export default router;
