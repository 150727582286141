<template lang="pug">
Main
  h1.title {{ $t("titles.channels") }}
  h2.subtitle {{ $t("channels.subtitle") }}

  p.has-text-right.mb-4
    router-link.button.is-info.is-light(:to='{ name: "channels.new" }') {{ $t("channels.new") }}

  .box
    .filters.columns
      .column.is-3
        InputFilter(
          :title='$t("channels.filters.search")',
          v-model='form.query'
        )
      .column.is-3
        ListFilter(
          :title='$t("channels.filters.moderation.title")',
          :placeholder='$t("channels.filters.moderation.placeholder")',
          :options='filters.moderation',
          v-model='form.filters.moderation'
        )

    div(v-if='channels && channels.length > 0')
      table.table.is-fullwidth
        tbody
          tr(v-for='channel in channels')
            td.shrink
              figure.image.is-square.is-32x32
                img.is-rounded(v-if='channel.picture', :src='channel.picture')
                img.is-rounded(v-else, :src='assets.placeholder')
            td
              | {{ channel.name }}
              br
              span
                b-tag.mr-1(v-if='channel.private') {{ $t("channels.private") }}
                b-tag.mr-1(
                  v-if='channel.audience && channel.audiences.length > 0'
                ) {{ $tc("channels.audiences", channel.audiences.length, { count: channel.audiences.length }) }}
                b-tag.mr-1(v-if='channel.public') {{ $t("channels.public") }}
            td.has-text-right
              .level.is-inline-block: .level-right
                .level-item(v-if='channel.moderated')
                  b-tag(size='is-small') {{ $t("channels.moderated") }}
                .level-item
                  router-link.button.is-small.is-info.is-light(
                    :to='{ name: "channels.edit", params: { uuid: channel.uuid } }'
                  ) {{ $t("channels.edit") }}
                .level-item
                  b-button(
                    type='is-danger is-light',
                    size='is-small',
                    icon-left='delete',
                    @click='deleteChannel(channel.uuid)'
                  )

      b-pagination(
        v-if='channelCount > config.feed.channels.per_page',
        v-model='currentPage',
        :total='channelCount',
        :per-page='config.feed.channels.per_page',
        :range-before='2',
        :range-after='2',
        :rounded='true',
        size='is-small'
      )

    template(v-else-if='channels && channels.length === 0')
      Empty(
        v-if='form.filters.moderation !== undefined || form.query !== undefined',
        :messageOverride='$t("placeholders.channels_filters")'
      )
      Empty(v-else, :messageOverride='$t("placeholders.channels")')

    table.table.is-fullwidth(v-else)
      tbody
        tr(v-for='n in 3')
          td
            b-skeleton(width='200px')
            b-skeleton(width='200px', size='is-small')
          td: b-skeleton(width='60px', position='is-right', size='is-large')

    Empty(v-else)
</template>

<script>
import Placeholder from '@/images/placeholder-image.png';

import { mapState } from 'vuex';

import ChannelsService from '@/services/ChannelsService';

import Main from '@/components/Main.vue';
import Empty from '@/components/partials/Empty.vue';
import ListFilter from '@/components/partials/ListFilter.vue';
import InputFilter from '@/components/partials/InputFilter.vue';

export default {
  components: {
    Main,
    Empty,
    ListFilter,
    InputFilter,
  },

  data: () => ({
    assets: {
      placeholder: Placeholder,
    },
    form: {
      query: undefined,
      filters: {
        moderation: undefined,
      },
    },
    channels: undefined,
    channelCount: undefined,
    currentPage: 1,
  }),

  async mounted() {
    this.$store.commit('setTitle', { title: 'head.channels' });

    if (this.$route.query.page) {
      const page = parseInt(this.$route.query.page, 10);

      if (!Number.isNaN(page)) {
        this.currentPage = page;
      } else {
        this.currentPage = 1;
        this.$router.push({ query: { page: 1 } });
      }
    } else {
      this.currentPage = 1;
    }

    this.loadPage();
  },

  computed: {
    filters() {
      return {
        moderation: [
          { slug: 'all', label: this.$t('channels.filters.moderation.options.all') },
          { slug: 'moderated', label: this.$t('channels.filters.moderation.options.moderated') },
          { slug: 'unmoderated', label: this.$t('channels.filters.moderation.options.unmoderated') },
        ],
      };
    },

    ...mapState({
      config: 'config',
    }),
  },

  watch: {
    currentPage(page) {
      if (parseInt(this.$route.query.page, 10) !== this.currentPage) {
        this.$router.push({ query: { page } });
      }

      this.loadPage();
    },

    'form.query': function queryWatcher() {
      this.currentPage = 1;
      this.loadPage();
    },

    'form.filters': {
      handler() {
        this.currentPage = 1;
        this.loadPage();
      },
      deep: true,
    },
  },

  methods: {
    loadPage() {
      this.channels = undefined;

      ChannelsService.list(this.currentPage, this.form.query, this.form.filters).then((channels) => {
        this.channels = channels.data;
        this.channelCount = channels.count;
      });
    },

    deleteChannel(uuid) {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        hasIcon: true,
        title: this.$t('channels.delete_confirm_title'),
        message: this.$t('channels.delete_confirm_content'),
        cancelText: this.$t('global.cancel'),
        onConfirm: () => {
          ChannelsService.delete(uuid)
            .then((_response) => {
              this.$flash.add('success', 'channels.delete_success');
              this.loadPage();
            })
            .catch((error) => {
              this.$flash.add('danger', 'channels.delete_error', error.response.data.message);
            });
        },
      });
    },
  },
};
</script>

<style lang="scss">
table td {
  vertical-align: middle !important;
}

figure img {
  border: 1px solid #dedede;
}
</style>
