import Vue from 'vue';
import Buefy from 'buefy';
import VueFlash from '@/ext/VueFlash';
import Vuelidate from 'vuelidate';

import { i18n, loadLocale, loadDefaultLocale } from '@/locales/locales';
import store from '@/store';
import router from '@/router';
import App from '@/components/App.vue';
import helpers from '@/components/helpers';

import { createOidcAuth, SignInType } from 'vue-oidc-client';
import { WebStorageStateStore } from 'oidc-client';
import ConfigService from '@/services/ConfigService';
import SessionService from '@/services/SessionService';
import _ from '@/ext/Dates';

import '@mdi/font/scss/materialdesignicons.scss';
import 'vue-multiselect/dist/vue-multiselect.min.css';

document.addEventListener('DOMContentLoaded', () => {
  ConfigService.config(store).then((config) => {
    const oidc = createOidcAuth('asconnect', SignInType.Window, 'http://oidcjs.localhost:3000/', {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: config.oidc.authority,
      client_id: config.oidc.client_id,
      response_type: 'code',
      redirect_uri: config.oidc.redirect_uri,
      silent_redirect_uri: config.oidc.refresh_redirect_uri,
      scope: config.oidc.scope,
      automaticSilentRenew: true,
      prompt: 'consent',
    });

    Vue.use(Vuelidate);
    Vue.use(Buefy);
    Vue.use(VueFlash, { store });

    oidc.startup().then((ok) => {
      if (ok) {
        if (localStorage.getItem('locale')) {
          loadLocale(localStorage.getItem('locale'));
        } else {
          loadDefaultLocale();
        }

        oidc.useRouter(router);

        oidc.$on('userLoaded', (user) => {
          SessionService.setAsConnectCredentials(store, user);

          ConfigService.intercomConfig().then(() => {
            console.log('Intercom integration set up.');
          });
        });

        if (oidc.isAuthenticated) {
          SessionService.setAsConnectCredentials(store, oidc.user);

          ConfigService.intercomConfig().then(() => {
            console.log('Intercom integration set up.');
          });
        }

        if (localStorage.getItem('access_token')) {
          SessionService.setLocalCredentials(
            store,
            localStorage.getItem('access_token'),
            localStorage.getItem('refresh_token'),
            localStorage.getItem('access_token_expires_in'),
            localStorage.getItem('access_token_expires_at'),
          );
        }

        Vue.prototype.$oidc = oidc;

        const _app = new Vue({
          el: '#app',
          store,
          router,
          i18n,
          mixins: [
            helpers,
          ],
          components: { App },
        });
      }
    });
  });
});
