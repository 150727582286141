<template lang="pug">
Main
  h1.title {{ $t("titles.users_edit") }}
  h2.subtitle {{ $t("users.subtitle_edit") }}

  .box
    .form(v-if='newForm || user')
      b-field(:label='$t("users.name")', :type='fieldClass($v.form.name)')
        b-input(
          type='text',
          :disabled='!newForm && user.admin',
          placeholder='Amandine CLÉMENT',
          v-model='$v.form.name.$model',
          @keyup.native.enter='create'
        )

      b-field(:label='$t("users.email")', :type='fieldClass($v.form.email)')
        b-input(
          type='text',
          :disabled='!newForm',
          placeholder='amandine.clement@example.com',
          v-model='$v.form.email.$model',
          @keyup.native.enter='create'
        )

      b-field(
        v-if='newForm || !user.admin',
        :label='$t("users.writer_channels")'
      )
        CustomSelect(
          v-if='channels',
          v-model='form.writerChannels',
          @input='setWriterChannels',
          :multiple='true',
          :options='channels',
          track-by='uuid',
          label='name',
          :placeholder='$t("users.restrict_publish")'
        )

      b-field(
        v-if='newForm || !user.admin',
        :label='$t("users.moderator_channels")'
      )
        CustomSelect(
          v-if='channels',
          v-model='form.moderatorChannels',
          @input='setModeratorChannels',
          :multiple='true',
          :options='channels',
          track-by='uuid',
          label='name',
          :placeholder='$t("users.restrict_moderation")'
        )

      b-field
        p.control
          b-button.is-info.is-light(
            @click='create',
            :loading='isSubmitting',
            :disabled='$v.$invalid'
          )
            template(v-if='newForm') {{ $t("users.create") }}
            template(v-else) {{ $t("users.update") }}

    div(v-else)
      .mb-2
        b-skeleton(width='200px')
        b-skeleton(size='is-large', width='300px')

      .mb-2
        b-skeleton(width='200px')
        b-skeleton(size='is-large', width='300px')

      .mb-2
        b-skeleton(width='200px')
        b-skeleton(size='is-large', width='300px')

      .mt-2: b-skeleton(size='is-large', width='200px')
</template>

<script>
import _ from 'lodash';

import ChannelsService from '@/services/ChannelsService';
import UsersService from '@/services/UsersService';
import { required, email } from 'vuelidate/lib/validators';

import Main from '@/components/Main.vue';
import Multiselect from 'vue-multiselect';
import CustomSelect from '@/components/partials/CustomSelect.vue';

export default {
  components: {
    Main,
    Multiselect,
    CustomSelect,
  },

  props: {
    newForm: Boolean,
  },

  data: () => ({
    user: undefined,
    channels: undefined,
    isLoaded: false,
    isSubmitting: false,
    form: {
      name: '',
      email: '',
      writerChannels: [],
      moderatorChannels: [],
    },
  }),

  validations: {
    form: {
      name: { required },
      email: { required, email },
    },
  },

  async mounted() {
    ChannelsService.all().then((channels) => {
      this.channels = channels.data.map((channel) => ({
        uuid: channel.uuid,
        name: channel.name,
      }));
    });

    if (!this.newForm) {
      UsersService.get(this.$route.params.uuid).then((user) => {
        this.$store.commit('setTitle', { title: 'head.user', params: { user: user.name } });

        this.user = user;
        this.form.email = user.email;
        this.form.name = user.name;

        this.form.writerChannels = user.channels
          .filter((channel) => channel.role === 'writer')
          .map((channel) => ({
            uuid: channel.uuid,
            name: channel.name,
          }));

        this.form.moderatorChannels = user.channels
          .filter((channel) => channel.role === 'moderator')
          .map((channel) => ({
            uuid: channel.uuid,
            name: channel.name,
          }));
      });
    } else {
      this.$store.commit('setTitle', { title: 'head.user_new' });
    }
  },

  methods: {
    fieldClass(field) {
      if (field.$dirty && field.$invalid) {
        return 'is-danger';
      }

      return '';
    },

    setWriterChannels() {
      this.form.writerChannels.forEach((channel) => {
        this.form.moderatorChannels = _.reject(this.form.moderatorChannels, (moderatorChannel) => moderatorChannel.uuid === channel.uuid);
      });
    },

    setModeratorChannels() {
      this.form.moderatorChannels.forEach((channel) => {
        this.form.writerChannels = _.reject(this.form.writerChannels, (writerChannel) => writerChannel.uuid === channel.uuid);
      });
    },

    create() {
      if (!this.$v.$invalid) {
        this.isSubmitting = true;

        const writerChannels = this.form.writerChannels.map((channel) => channel.uuid);
        const moderatorChannels = this.form.moderatorChannels.map((channel) => channel.uuid);

        if (this.newForm) {
          UsersService.create(this.form.email, this.form.name, writerChannels, moderatorChannels)
            .then((_response) => {
              this.$flash.add('success', 'users.new_success');
              this.$router.push({ name: 'users.list' });
            })
            .catch((error) => {
              this.isSubmitting = false;
              this.$flash.add('danger', 'users.new_error', error.response.data.message);
            });
        } else {
          UsersService.update(this.$route.params.uuid, this.form.name, writerChannels, moderatorChannels)
            .then((_response) => {
              this.$flash.add('success', this.$t('users.edit_success'));
              this.$router.push({ name: 'users.list' });
            })
            .catch((error) => {
              this.isSubmitting = false;
              this.$flash.add('danger', 'users.edit_error', error.response.data.message);
            });
        }
      }
    },
  },
};
</script>
