<template lang="pug">
b-sidebar(v-if='$store.state.principal', fullheight, open, position='static')
  div(v-if='isLoaded && channels.length > 0')
    b-field.mt-3.mx-2
      b-input(
        rounded,
        size='is-small',
        icon='magnify',
        v-model='filter',
        @input='applyFilter'
      )

    .channel.p-3(
      v-for='channel in actualChannels',
      :class='{ selected: local.selectedChannel && channel.uuid === local.selectedChannel.uuid, readonly: readOnly }',
      @click='selectChannel(channel)'
    )
      .level.is-justify-content-flex-start
        .level-left.mr-4
          .level-item
            .image.is-32x32.has-text-centered.is-flex.is-align-items-center.is-justify-content-center(
              v-if='channel.uuid == "pinned"'
            )
              b-icon(icon='pin', size='is-small')
            .image.is-square.is-32x32(v-else)
              img.is-rounded(v-if='channel.picture', :src='channel.picture')
              img.is-rounded(v-else, :src='assets.placeholder')
        .level-right
          .level-item.is-block
            p {{ channel.name }}
            p.subtext.has-text-grey.is-uppercase(
              v-if='channel.role === "writer"'
            ) {{ $t("channels.writer") }}
            p.subtext.has-text-grey.is-uppercase(
              v-if='channel.role === "moderator" || $root.isAdmin()'
            ) {{ $t("channels.moderator") }}

  div(v-else)
    div(v-if='!isLoaded')
      .channel.p-3(v-for='n in 3')
        .level.is-justify-content-flex-start
          .level-left.mr-4
            .level-item
              .image.is-32x32
                b-skeleton(circle, width='32px', height='32px')
          .level-right.is-flex-grow-1
            .level-item.is-block.is-flex-grow-1
              p: b-skeleton(width='100%')
              p: b-skeleton(width='100%')

    div(v-else)
      p.m-2.has-text-centered.has-text-grey {{ $t("channels.empty") }}
</template>

<script>
import Placeholder from '@/images/placeholder-image.png';

export default {
  props: {
    isLoaded: Boolean,
    channels: Array,
    selectedChannel: Object,
    readOnly: Boolean,
  },

  data: () => ({
    assets: {
      placeholder: Placeholder,
    },
    local: {
      selectedChannel: undefined,
    },
    filter: '',
    filteredChannels: undefined,
  }),

  computed: {
    actualChannels() {
      const pinned = {
        uuid: 'pinned',
        name: this.$t('posts.pinned_posts'),
      };

      if (this.filteredChannels !== undefined) {
        return [pinned].concat(this.filteredChannels);
      }

      return [pinned].concat(this.channels);
    },
  },

  methods: {
    applyFilter() {
      if (this.filter.trim() === '') {
        this.filteredChannels = undefined;
      }

      this.filteredChannels = this.channels.filter((channel) => channel.name.toLowerCase().includes(this.filter.trim().toLowerCase()));
    },

    selectChannel(channel) {
      if (!this.readOnly) {
        if (this.local.selectedChannel && this.local.selectedChannel.uuid === channel.uuid) {
          return;
        }

        this.local.selectedChannel = channel;

        this.$emit('channel-selected', channel);

        this.$router.push({
          name: 'posts.list',
          params: { channel: channel.uuid },
        });
      }
    },
  },

  watch: {
    selectedChannel(value) {
      this.local.selectedChannel = value;
    },
  },
};
</script>

<style lang="scss">
.b-sidebar {
  z-index: 10;

  .sidebar-content {
    background: white;
  }

  .image img {
    border: 1px solid #dedede;
  }
}

.channel {
  border-right: 2px solid transparent;
  cursor: pointer;

  .subtext {
    font-size: 0.65rem !important;
  }

  &.readonly {
    cursor: default;
    opacity: 0.5;

    &:hover {
      pointer-events: none;
    }
  }

  &:hover {
    background: #fafafa;
  }

  &.selected {
    border-color: #0499bc;
    background: #f3f3f3;
  }
}
</style>
