<template lang="pug">
.my-6.has-text-centered.has-text-grey
  b-icon.mb-2(icon='crop-free', size='is-medium')
  p {{ message }}
</template>

<script>
export default {
  props: {
    messageOverride: String,
  },

  computed: {
    message() {
      if (this.messageOverride) {
        return this.messageOverride;
      }

      return this.$t('global.empty');
    },
  },
};
</script>
