<template lang="pug">
Main
  template(#sidebar)
    Sidebar(
      :isLoaded='isLoaded',
      :channels='channels',
      :selectedChannel='selectedChannel',
      :readOnly='true'
    )

  div(v-if='selectedChannel')
    .columns.is-vcentered
      .column.is-1
        .image.is-square
          img.is-rounded(
            v-if='selectedChannel && selectedChannel.picture',
            :src='selectedChannel.picture'
          )
          img.is-rounded(v-else, :src='assets.placeholder')
      .column.is-11
        h1.title {{ $t("titles.posts_new") }}
        h2.subtitle {{ selectedChannel.name }}

    .box
      .is-pulled-right(v-if='$store.state.principal.admin')
        b-switch(size='is-small', v-model='form.pinned') {{ $t("posts.pin") }}

      h3.is-size-5 {{ $t("channels.visibilities") }}

      b-field
        b-checkbox-button(
          v-if='selectedChannel.public',
          v-model='form.visibilities',
          size='is-small',
          type='is-info',
          native-value='public',
          @input='visibilityChanged("public")'
        ) {{ $t("channels.public") }}
        b-checkbox-button(
          v-if='selectedChannel.private',
          v-model='form.visibilities',
          size='is-small',
          type='is-info',
          native-value='private',
          @input='visibilityChanged("private")'
        ) {{ $t("channels.private") }}
        b-checkbox-button(
          v-if='selectedChannel.audience',
          v-model='form.visibilities',
          size='is-small',
          type='is-info',
          native-value='audience',
          @input='visibilityChanged("audience")'
        ) {{ $t("channels.audience") }}

      b-notification(
        v-if='form.visibilities.includes("private") || form.visibilities.includes("public")',
        type='is-warning is-light',
        :closable='false'
      ) {{ audienceWarning }}

      .field(
        v-if='form.visibilities.includes("audience") && selectedChannel.audiences.length > 0'
      )
        CustomSelect(
          v-model='form.audiences',
          :multiple='true',
          :options='selectedChannel.audiences',
          track-by='slug',
          label='name',
          :placeholder='$t("posts.restrict_audiences")'
        )

    .box
      h3.is-size-5.mb-3 {{ $t("posts.content") }}

      PostEditor(v-model='form.content')

    .columns
      .column
        .box
          h2.is-size-5 {{ $t("posts.media") }}
          p.is-size-7.mb-3 {{ $t("posts.media_prompt") }}

          .picture-upload(@click='launchPictureUpload')
            .preview(ref='picture')
            b-upload.is-hidden(v-model='form.picture', ref='pictureUpload')
            b-icon(size='is-medium', icon='upload')

          .has-text-centered.mt-2(v-if='form.picture')
            b-button(
              type='is-danger is-light',
              size='is-small',
              @click='deletePicture'
            ) {{ $t("posts.delete_picture") }}

      .column

    b-field
      p.control
        b-button.is-info.is-light(
          @click='create()',
          :loading='isSubmitting',
          :disabled='$v.$invalid'
        ) {{ $t("posts.create") }}
</template>

<script>
import Placeholder from '@/images/placeholder-image.png';

import ChannelsService from '@/services/ChannelsService';
import PostsService from '@/services/PostsService';
import { required } from 'vuelidate/lib/validators';

import Main from '@/components/Main.vue';
import Sidebar from '@/components/posts/Sidebar.vue';
import PostEditor from '@/components/partials/PostEditor.vue';
import CustomSelect from '@/components/partials/CustomSelect.vue';

export default {
  components: {
    Main,
    Sidebar,
    PostEditor,
    CustomSelect,
  },

  data: () => ({
    assets: {
      placeholder: Placeholder,
    },
    isLoaded: false,
    isSubmitting: false,
    channels: [],
    selectedChannel: undefined,
    form: {
      content: '',
      visibilities: [],
      audiences: [],
      picture: undefined,
      pinned: false,
    },
  }),

  computed: {
    audienceWarning() {
      if (this.form.visibilities.includes('private') && this.form.visibilities.includes('public')) {
        return this.$t('posts.audience_warning_all');
      }
      if (this.form.visibilities.includes('private')) {
        return this.$t('posts.audience_warning_private');
      }
      if (this.form.visibilities.includes('public')) {
        return this.$t('posts.audience_warning_public');
      }

      return '';
    },
  },

  validations: {
    form: {
      content: {
        required,
        contentNotEmpty: (value) => value !== '<p></p>',
      },
      visibilities: { required },
      audiences: (value) => !value.visibilities.includes('audience') || value.audiences.length > 0,
    },
  },

  watch: {
    'form.picture': function pictureWatcher(value) {
      if (value === undefined) {
        return;
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        this.$refs.picture.style.background = `url(${e.target.result})`;
      };

      reader.readAsDataURL(value);
    },
  },

  async mounted() {
    this.$store.commit('setTitle', { title: 'head.post_new' });

    ChannelsService.all().then((channels) => {
      this.isLoaded = true;
      this.channels = channels.data;
      this.selectedChannel = this.channels.find((c) => c.uuid === this.$route.params.channel);

      if (this.selectedChannel.private && !this.selectedChannel.public && !this.selectedChannel.audience) {
        this.form.visibilities = ['private'];
      }
      if (this.selectedChannel.public && !this.selectedChannel.private && !this.selectedChannel.audience) {
        this.form.visibilities = ['public'];
      }
      if (this.selectedChannel.audience && !this.selectedChannel.public && !this.selectedChannel.private) {
        this.form.visibilities = ['audience'];
      }
    });
  },

  methods: {
    fieldClass(field) {
      if (field.dirty && field.$invalid) {
        return 'is-danger';
      }

      return '';
    },

    visibilityChanged(visibility) {
      if (visibility === 'private' && this.form.visibilities.includes('audience')) {
        this.form.visibilities.splice(this.form.visibilities.indexOf('audience'), 1);
      }
      if (visibility === 'audience' && this.form.visibilities.includes('private')) {
        this.form.visibilities.splice(this.form.visibilities.indexOf('private'), 1);
      }
    },

    launchPictureUpload() {
      this.$refs.pictureUpload.$el.click();
    },

    deletePicture() {
      this.form.picture = undefined;
      this.$refs.picture.style.background = 'none';
    },

    create() {
      this.isSubmitting = true;

      const audiences = this.form.audiences.map((audience) => audience.slug);

      PostsService.create(this.selectedChannel.uuid, this.form.visibilities, audiences, this.form.content, this.form.picture, this.form.pinned)
        .then((_response) => {
          this.$flash.add('success', 'posts.new_success');
          this.$router.push({
            name: 'posts.list',
            params: { channel: this.selectedChannel.uuid },
          });
        })
        .catch((error) => {
          this.isSubmitting = false;
          this.$flash.add('danger', 'posts.new_error', error.response.data.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.picture-upload {
  position: relative;
  overflow: hidden;
  height: 400px;
  border: 1px dotted #989898;

  .preview {
    height: 100%;
    background-size: cover !important;
  }

  span.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
