<template lang="pug">
b-notification(:type='type()') {{ $t(message) }}
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
  },

  async mounted() {
    this.$flash.seen(this.id);
  },

  methods: {
    type() {
      return `is-${this.variant}`;
    },
  },
};
</script>
