import Vue from 'vue';
import VueI18n from 'vue-i18n';
import DefaultMessages from '@/locales/en';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: DefaultMessages,
});

const loadedLocales = ['en'];

function setLocale(locale) {
  i18n.locale = locale;

  document.querySelector('html').setAttribute('lang', locale);
  // document.title = i18n.messages[locale].titles.title;

  localStorage.setItem('locale', locale);

  return locale;
}

export async function loadLocale(locale) {
  if (i18n.locale === locale) {
    return Promise.resolve(setLocale(locale));
  }

  if (loadedLocales.includes(locale)) {
    return Promise.resolve(setLocale(locale));
  }

  return import(/* webpackChunkName: "lang-[request]" */ `@/locales/${locale}.js`).then((messages) => {
    i18n.setLocaleMessage(locale, messages.default[locale]);
    loadedLocales.push(locale);

    return setLocale(locale);
  });
}

export function loadDefaultLocale() {
  const defaultLocale = navigator.language || navigator.userLanguage;
  const [locale, ..._] = defaultLocale.split('-');

  loadLocale(locale);
}
