<template lang="pug">
Main
  h1.title {{ $t("titles.users") }}
  h2.subtitle {{ $t("users.subtitle") }}

  p.has-text-right.mb-4
    router-link.button.is-info.is-light(:to='{ name: "users.new" }') {{ $t("users.new") }}

  .box
    .filters.columns
      .column.is-3
        InputFilter(:title='$t("users.filters.search")', v-model='form.query')

    div(v-if='users && users.length > 0')
      table.table.is-fullwidth
        tbody
          tr(v-for='user in users')
            td
              span.name {{ user.name }}
              b-tag.ml-2(
                v-if='!user.verified',
                type='is-warning is-light',
                :rounded='true'
              ) {{ $t("users.unverified") }}
              b-tag.ml-2(v-if='user.admin', :rounded='true') {{ $t("users.admin") }}
            td {{ user.email }}
            td.has-text-right
              router-link.button.is-small.is-info.is-light(
                v-if='!user.admin',
                :to='{ name: "users.edit", params: { uuid: user.uuid } }'
              ) {{ $t("users.edit") }}
              b-button.ml-1(
                v-if='!user.admin',
                type='is-danger is-light',
                size='is-small',
                icon-left='delete',
                @click='deleteUser(user.uuid)'
              )

      b-pagination(
        v-if='userCount > config.feed.users.per_page',
        v-model='currentPage',
        :total='userCount',
        :per-page='config.feed.users.per_page',
        :range-before='2',
        :range-after='2',
        :rounded='true',
        size='is-small'
      )

    Empty(v-else-if='users && users.length === 0')

    table.table.is-fullwidth(v-else)
      tbody
        tr(v-for='n in 3')
          td: b-skeleton(width='120px')
          td: b-skeleton
          td: b-skeleton(width='60px', position='is-right', size='is-large')
</template>

<script>
import UsersService from '@/services/UsersService';

import { mapState } from 'vuex';

import Main from '@/components/Main.vue';
import Empty from '@/components/partials/Empty.vue';
import InputFilter from '@/components/partials/InputFilter.vue';

export default {
  components: {
    Main,
    Empty,
    InputFilter,
  },

  data: () => ({
    form: {
      query: undefined,
    },
    users: undefined,
    userCount: undefined,
    currentPage: 1,
  }),

  async mounted() {
    this.$store.commit('setTitle', { title: 'head.users' });

    if (this.$route.query.page) {
      const page = parseInt(this.$route.query.page, 10);

      if (!Number.isNaN(page)) {
        this.currentPage = page;
      } else {
        this.currentPage = 1;
        this.$router.push({ query: { page: 1 } });
      }
    } else {
      this.currentPage = 1;
    }

    this.loadPage();
  },

  computed: mapState({
    config: 'config',
  }),

  watch: {
    currentPage(page) {
      if (parseInt(this.$route.query.page, 10) !== this.currentPage) {
        this.$router.push({ query: { page } });
      }

      this.loadPage();
    },

    'form.query': function queryWatcher() {
      this.currentPage = 1;
      this.loadPage();
    },
  },

  methods: {
    loadPage() {
      UsersService.list(this.currentPage, this.form.query).then((users) => {
        this.users = users.data;
        this.userCount = users.count;
      });
    },

    deleteUser(uuid) {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        hasIcon: true,
        title: this.$t('users.delete_confirm_title'),
        message: this.$t('users.delete_confirm_content'),
        cancelText: this.$t('global.cancel'),
        onConfirm: () => {
          UsersService.delete(uuid)
            .then((_response) => {
              this.$flash.add('success', this.$t('users.delete_success'));
              this.loadPage();
            })
            .catch((error) => {
              this.$flash.add('danger', 'users.delete_error', error.response.data.message);
            });
        },
      });
    },
  },
};
</script>

<style lang="scss">
.vue-content-placeholders-text__line {
  margin: 0 !important;
}
</style>
