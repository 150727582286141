<template lang="pug">
Main
  h1.title {{ $t("titles.account") }}

  .box
    h3.is-size-5.mb-3 {{ $t("account.change_password") }}

    .form
      b-field(:label='$t("signup.password")')
        b-input(
          ref='password',
          type='password',
          placeholder='*******',
          autocomplete='new-password',
          v-model='$v.form.password.$model',
          @keyup.native.enter='changePassword'
        )

      password(
        v-model='form.password',
        :strengthMeterOnly='true',
        :badge='false',
        :secureLength='12'
      )

      b-field(:label='$t("signup.password_confirmation")')
        b-input(
          type='password',
          placeholder='*******',
          v-model='$v.form.passwordConfirmation.$model',
          @keyup.native.enter='changePassword'
        )

      b-field
        p.control
          b-button.is-info.is-light(
            @click='changePassword',
            :loading='isPasswordChangeSubmitting',
            :disabled='$v.$invalid',
            @keyup.native.enter='create'
          ) {{ $t("account.change_password") }}
</template>

<script>
import SessionService from '@/services/SessionService';
import { required, sameAs } from 'vuelidate/lib/validators';

import Main from '@/components/Main.vue';
import Password from 'vue-password-strength-meter';

export default {
  components: {
    Main,
    Password,
  },

  data: () => ({
    isPasswordChangeSubmitting: false,
    form: {
      password: '',
      passwordConfirmation: '',
    },
  }),

  validations: {
    form: {
      password: { required },
      passwordConfirmation: {
        sameAsPassword: sameAs('password'),
      },
    },
  },

  async mounted() {
    this.$refs.password.focus();
  },

  methods: {
    changePassword() {
      if (!this.$v.$invalid) {
        this.isPasswordChangeSubmitting = true;

        SessionService.changePassword(this.form.password, this.form.passwordConfirmation)
          .then(() => {
            this.isPasswordChangeSubmitting = false;

            this.form.password = '';
            this.form.passwordConfirmation = '';

            this.$flash.add('success', 'account.change_password_success');
          })
          .catch((error) => {
            this.isPasswordChangeSubmitting = false;
            this.$flash.add('danger', 'account.change_password_error', error.response.data.message);
          });
      }
    },
  },
};
</script>
