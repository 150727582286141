<template lang="pug">
footer.footer.mt-6
  .container
    p Copyright © 2021 • AppScho

    .content.mt-4
      .field.is-grouped
        p.control
          a.button.is-small(@click='setLocale("en")') EN
        p.control
          a.button.is-small(@click='setLocale("fr")') FR
</template>

<script>
import { loadLocale } from '@/locales/locales';

export default {
  methods: {
    async setLocale(locale) {
      await loadLocale(locale);
    },
  },
};
</script>
