<template lang="pug">
multiselect(
  v-bind='$attrs',
  v-on='$listeners',
  :multiple='multiple',
  :close-on-select='isCloseOnSelect',
  :selectLabel='$t("multiselect.enter_to_select")',
  :deselectLabel='$t("multiselect.enter_to_remove")',
  :selectedLabel='$t("multiselect.selected")'
)
  span(slot='noResult') {{ $t("multiselect.no_result") }}
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  props: {
    multiple: Boolean,
  },

  components: {
    Multiselect,
  },

  inheritAttrs: false,

  computed: {
    isCloseOnSelect() {
      return !this.multiple;
    },
  },
};
</script>
