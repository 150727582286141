<template lang="pug">
Main
  h1.title {{ $t("titles.signin") }}

  .box
    .form
      .is-grouped.has-text-right
        b-button.mr-4.mb-4.is-info.is-light(
          size='is-small',
          icon-left='school',
          :label='$t("signin.asconnect")',
          @click='asConnectSignIn'
        )

    .field
      label.label {{ $t("signup.email") }}
      input.input(
        ref='email',
        type='text',
        placeholder='amandine.clement@appscho.com',
        v-model='form.email',
        @keyup.enter='signIn'
      )

    .field
      label.label {{ $t("signup.password") }}
      input.input(
        type='password',
        placeholder='********',
        v-model='form.password',
        @keyup.enter='signIn'
      )
      p.has-text-right.is-size-7
        router-link(:to='{ name: "sessions.reset" }') {{ $t("titles.reset") }}

    .field
      p.control
        b-button.is-info.is-light(
          @click='signIn',
          :loading='isSubmitting',
          :disabled='$v.$invalid'
        ) {{ $t("titles.signin") }}
</template>

<script>
import AuthService from '@/services/AuthService';
import SessionService from '@/services/SessionService';
import { required, email } from 'vuelidate/lib/validators';

import Main from '@/components/Main.vue';

export default {
  components: {
    Main,
  },

  data: () => ({
    isSubmitting: false,
    form: {
      email: '',
      password: '',
    },
  }),

  validations: {
    form: {
      email: { required, email },
      password: { required },
    },
  },

  async mounted() {
    this.$store.commit('setTitle', { title: 'head.signin' });

    this.$refs.email.focus();
  },

  methods: {
    asConnectSignIn() {
      this.$oidc.signIn();
    },

    signIn() {
      if (!this.$v.$invalid) {
        this.isSubmitting = true;

        AuthService.signIn(this.form.email, this.form.password)
          .then((response) => {
            SessionService.setLocalCredentials(this.$store, response.data.token, response.data.refresh_token, response.data.expires_in, response.data.expires_at);

            this.$flash.add('success', 'flashes.signin_successful');
            this.$router.push({ name: 'posts.list' });
          })
          .catch((_error) => {
            this.isSubmitting = false;
            this.$flash.add('danger', 'flashes.signin_failed');

            this.form.password = '';
          });
      }
    },
  },
};
</script>
