<template lang="pug">
.content
  div
    p.buttons.mb-0
      button.button(
        :class='{ "is-active": editor.isActive("bold") }',
        @click='editor.chain().toggleBold().focus().run()'
      )
        b-icon(icon='format-bold', size='is-small')
      button.button(
        :class='{ "is-active": editor.isActive("italic") }',
        @click='editor.chain().toggleItalic().focus().run()'
      )
        b-icon(icon='format-italic', size='is-small')

      button.button(v-if='!editor.isActive("link")', @click='setLink')
        b-icon(icon='link', size='is-small')
      button.button.is-active(
        v-if='editor.isActive("link")',
        @click='editor.chain().focus().unsetLink().run()'
      )
        b-icon(icon='link-off', size='is-small')
  div
    editor-content(:editor='editor')
    p.has-text-right {{ editor.getCharacterCount() }} / {{ maxSize }}
</template>

<script>
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';

import { Editor, EditorContent } from '@tiptap/vue-2';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import CharacterCount from '@tiptap/extension-character-count';

const MAX_SIZE = 1000;

export default {
  components: {
    EditorContent,
  },

  data() {
    return {
      size: 0,
      maxSize: MAX_SIZE,
      editor: new Editor({
        extensions: [
          Document,
          Paragraph,
          Text,
          Bold,
          Italic,
          Link,
          CharacterCount.configure({
            limit: MAX_SIZE,
          }),
        ],
        onUpdate: () => {
          this.$emit('input', this.editor.getHTML());
        },
      }),
    };
  },

  beforeDestroy() {
    this.editor.destroy();
  },

  methods: {
    setLink() {
      this.$buefy.dialog.prompt({
        trapFocus: true,
        closeOnConfirm: false,
        title: this.$t('posts.link_title'),
        message: this.$t('posts.link_content'),
        cancelText: this.$t('global.cancel'),
        onConfirm: (value, { close }) => {
          if (value.startsWith('https://')) {
            this.editor
              .chain()
              .focus()
              .setLink({ href: value })
              .run();

            close();
          }
        },
      });
    },
  },
};
</script>

<style lang="scss">
.ProseMirror {
  padding: 8px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  outline: none;
}
</style>
