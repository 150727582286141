import Vue from 'vue';
import { parseISO, format, formatDistance } from 'date-fns';

import { en, fr } from 'date-fns/locale';

Vue.mixin({
  methods: {
    formatDate(date) {
      let locale = en;

      switch (this.$i18n.locale) {
        case 'fr':
          locale = fr;
          break;
        default:
          locale = en;
      }

      return format(parseISO(date), 'dd MMM yyyy, HH:mm', { locale });
    },

    formatHuman(date) {
      let locale = en;

      switch (this.$i18n.locale) {
        case 'fr':
          locale = fr;
          break;
        default:
          locale = en;
      }

      return formatDistance(parseISO(date), new Date(), { locale });
    },
  },
});
