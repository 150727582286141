export default {
  install(Vue, { store }) {
    const vue = Vue;

    vue.prototype.$flash = {
      add(variant, message, upstreamMessage) {
        if (upstreamMessage) {
          store.commit('addFlash', { variant, message: `errors.${upstreamMessage}` });
        } else {
          store.commit('addFlash', { variant, message });
        }
      },

      seen(id) {
        store.commit('seenFlash', id);
      },

      clear() {
        store.commit('clearFlash');
      },
    };
  },
};
