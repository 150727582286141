<template lang="pug">
Main
  h1.title {{ $t("titles.reset") }}

  .box
    .field
      label.label {{ $t("signup.password") }}
      input.input(
        type='password',
        v-model='form.password',
        @keyup.enter='resetPassword'
      )

    password(
      v-model='form.password',
      :strengthMeterOnly='true',
      :badge='false',
      :secureLength='12'
    )

    .field
      label.label {{ $t("signup.password_confirmation") }}
      input.input(
        type='password',
        v-model='form.passwordConfirmation',
        @keyup.enter='resetPassword'
      )

    .field
      p.control
        b-button.is-info.is-light(
          @click='resetPassword',
          :loading='isSubmitting',
          :disabled='$v.$invalid'
        ) {{ $t("titles.reset") }}
</template>

<script>
import SessionService from '@/services/SessionService';
import { required, sameAs } from 'vuelidate/lib/validators';

import Main from '@/components/Main.vue';
import Password from 'vue-password-strength-meter';

export default {
  components: {
    Main,
    Password,
  },

  data: () => ({
    isSubmitting: false,
    form: {
      password: '',
      passwordConfirmation: '',
    },
  }),

  validations: {
    form: {
      password: { required },
      passwordConfirmation: {
        sameAsPassword: sameAs('password'),
      },
    },
  },

  async mounted() {
    this.$store.commit('setTitle', { title: 'head.reset' });
  },

  methods: {
    resetPassword() {
      if (!this.$v.$invalid) {
        this.isSubmitting = true;

        SessionService.resetPassword(this.$route.params.token, this.form.password, this.form.passwordConfirmation)
          .then((_response) => {
            this.$flash.add('success', 'reset.reset_success');
            this.$router.push({ name: 'sessions.signin' });
          })
          .catch((error) => {
            this.isSubmitting = false;
            this.$flash.add('danger', 'reset.reset_error', error.response.data.message);
          });
      }
    },
  },
};
</script>
