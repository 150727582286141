import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    config: null,
    session_interceptor_id: null,
    session_refresher_handle: null,
    access_token: null,
    access_token_expires_in: null,
    access_token_expires_at: null,
    refresh_token: null,
    principal: null,
    title: null,
    titleParams: {},
    flashes: {},
  },

  mutations: {
    setConfig(state, config) {
      state.config = config;
    },

    setAccessToken(state, {
      token, refreshToken, expiresIn, expiresAt,
    }) {
      state.access_token = token;
      state.refresh_token = refreshToken;
      state.access_token_expires_at = expiresAt;
      state.access_token_expires_in = expiresIn;
    },

    setPrincipal(state, principal) {
      state.principal = principal;
    },

    setSessionInterceptorId(state, id) {
      state.session_interceptor_id = id;
    },

    setSessionRefresherHandle(state, handle) {
      state.session_refresher_handle = handle;
    },

    deletePrincipal(state) {
      localStorage.clear();
      sessionStorage.clear();

      state.access_token = null;
      state.access_token_expires_in = null;
      state.access_token_expires_at = null;
      state.principal = null;

      axios.interceptors.request.eject(state.session_interceptor_id);
      clearInterval(state.session_refresher_handle);

      state.session_interceptor_id = null;
    },

    setTitle(state, { title, params }) {
      state.title = title;

      if (params === undefined) {
        state.titleParams = {};
      } else {
        state.titleParams = params;
      }
    },

    addFlash(state, { variant, message }) {
      this.commit('clearFlash');

      const id = Math.random().toString(36);

      Vue.set(state.flashes, id, { id, variant, message });
    },

    seenFlash(state, id) {
      if (id in state.flashes) {
        const flash = state.flashes[id];

        Vue.set(state.flashes, id, { seen: true, ...flash });
      }
    },

    clearFlash(state) {
      Object.values(state.flashes).forEach((flash) => {
        if (flash.seen) {
          delete state.flashes[flash.id];
        }
      });
    },
  },
});
