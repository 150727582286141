import axios from 'axios';

import AuthService from '@/services/AuthService';

const SESSION_REFRESHER_INTERVAL = 5000;

export default {
  verifyAccount(token, password, passwordConfirmation) {
    const body = {
      token,
      password,
      password_confirmation: passwordConfirmation,
    };

    return axios.post('/api/v1/id/verify', body);
  },

  setAsConnectCredentials(store, user) {
    store.commit('setAccessToken', { token: user.access_token });

    const id = axios.interceptors.request.use(async (config) => {
      const konfig = config;

      konfig.headers.Authorization = `Bearer ${user.access_token}`;

      return konfig;
    });

    axios.interceptors.request.eject(store.state.session_interceptor_id);
    store.commit('setSessionInterceptorId', id);
  },

  setLocalCredentials(store, token, refreshToken, expiresIn, expiresAt) {
    localStorage.setItem('access_token', token);
    localStorage.setItem('refresh_token', refreshToken);
    localStorage.setItem('access_token_expires_in', expiresIn);
    localStorage.setItem('access_token_expires_at', expiresAt);

    store.commit('setAccessToken', {
      token, refreshToken, expiresIn, expiresAt,
    });

    const id = axios.interceptors.request.use(async (config) => {
      const konfig = config;
      let accessToken = localStorage.getItem('access_token');

      if (konfig.url.includes('/api/v1/id/refresh')) {
        accessToken = localStorage.getItem('refresh_token');
      }

      konfig.headers.Authorization = `Bearer ${accessToken}`;

      return konfig;
    });

    const refresher = setInterval(() => {
      const accessTokenExpiresIn = localStorage.getItem('access_token_expires_in');
      const accessTokenExpiresAt = localStorage.getItem('access_token_expires_at');
      const now = Math.floor(Date.now() / 1000);

      if (now > (accessTokenExpiresAt - (accessTokenExpiresIn / 2))) {
        AuthService.refresh()
          .then((response) => this.setLocalStorage(store, response.data))
          .catch(() => store.commit('deletePrincipal'));
      }
    }, SESSION_REFRESHER_INTERVAL);

    store.commit('setSessionInterceptorId', id);
    store.commit('setSessionRefresherHandle', refresher);
  },

  setLocalStorage(store, response) {
    localStorage.setItem('access_token', response.token);
    localStorage.setItem('refresh_token', response.refresh_token);
    localStorage.setItem('access_token_expires_in', response.expires_in);
    localStorage.setItem('access_token_expires_at', response.expires_at);

    store.commit('setAccessToken', {
      token: response.token,
      refreshToken: response.refresh_token,
      expiresIn: response.expires_in,
      expiresAt: response.expires_at,
    });
  },

  changePassword(password, passwordConfirmation) {
    const body = {
      password,
      password_confirmation: passwordConfirmation,
    };

    return axios.post('/api/v1/id/password', body);
  },

  requestPasswordReset(email) {
    const body = {
      email,
    };

    return axios.post('/api/v1/id/password/request-reset', body);
  },

  resetPassword(token, password, passwordConfirmation) {
    const body = {
      token,
      password,
      password_confirmation: passwordConfirmation,
    };

    return axios.post('/api/v1/id/password/reset', body);
  },
};
