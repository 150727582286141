<template lang="pug">
Main
  h1.title {{ $t("titles.reset") }}

  .box
    .field
      label.label {{ $t("signup.email") }}
      input.input(
        ref='email',
        type='text',
        placeholder='amandine.clement@appscho.com',
        v-model='form.email',
        @keyup.enter='resetPassword'
      )

    .field
      p.control
        b-button.is-info.is-light(
          @click='resetPassword',
          :loading='isSubmitting',
          :disabled='$v.$invalid'
        ) {{ $t("titles.reset") }}
</template>

<script>
import SessionService from '@/services/SessionService';
import { required, email } from 'vuelidate/lib/validators';

import Main from '@/components/Main.vue';

export default {
  components: {
    Main,
  },

  data: () => ({
    isSubmitting: false,
    form: {
      email: '',
    },
  }),

  validations: {
    form: {
      email: { required, email },
    },
  },

  async mounted() {
    this.$store.commit('setTitle', { title: 'head.reset' });
  },

  methods: {
    resetPassword() {
      if (!this.$v.$invalid) {
        this.isSubmitting = true;

        SessionService.requestPasswordReset(this.form.email)
          .then(() => {
            this.isSubmitting = false;

            this.$flash.add('success', 'reset.reset_request_success');
            this.$router.push({ name: 'sessions.signin' });
          })
          .catch((error) => {
            this.isSubmitting = false;
            this.$flash.add('danger', 'reset.reset_error', error.response.data.message);
          });
      }
    },
  },
};
</script>
