<template lang="pug">
Main
  template(#sidebar)
    Sidebar(
      :isLoaded='isLoaded',
      :channels='channels',
      :selectedChannel='selectedChannel',
      @channel-selected='channelSelected'
    )

  .is-pulled-right
    b-button(
      v-if='recentlyPublished && recentlyPublished.count > 0',
      tag='router-link',
      :to='{ name: "moderation.recent" }',
      size='is-small',
      type='is-success is-light',
      icon-left='subdirectory-arrow-right'
    ) {{ $tc("posts.are_recently_published", this.recentlyPublished.count, { count: this.recentlyPublished.count }) }}

  .columns.is-vcentered.is-clearfix(v-if='selectedChannel')
    .column.is-1
      .image.is-square
        img.is-rounded(
          v-if='selectedChannel.picture',
          :src='selectedChannel.picture'
        )
        img.is-rounded(v-else, :src='assets.placeholder')
    .column.is-11(v-if='isPinnedChannel')
      h1.title {{ $t("posts.pinned_posts") }}
    .column.is-11(v-else)
      h1.title {{ selectedChannel.name }}
      h2.subtitle {{ $t("titles.posts") }}

  p.has-text-right.mb-4(v-if='!isPinnedChannel')
    router-link.button.is-info.is-light(
      v-if='selectedChannel',
      :to='{ name: "posts.new", params: { channel: this.selectedChannel.uuid } }'
    ) {{ $t("posts.new") }}

  .box
    .filters.columns(v-if='!isPinnedChannel')
      .column.is-3(v-if='posts')
        ListFilter(
          :title='$t("posts.filters.status.title")',
          :placeholder='$t("posts.filters.status.placeholder")',
          :options='filters.status',
          v-model='form.filters.status'
        )

    div(v-if='posts && posts.length > 0')
      table.table.is-fullwidth
        thead
          th.shrink
          th.shrink
          th.shrink {{ $t("posts.date") }}
          th.shrink {{ $t("posts.audiences") }}
          th {{ $t("posts.content") }}
          th.shrink
          th.shrink
        tbody
          tr(v-for='post in posts', :class='{ deleted: post.deleted }')
            td
              b-tooltip(
                v-if='post.pinned',
                :label='$t("posts.pinned")',
                type='is-black'
              )
                b-icon(icon='pin', size='is-small')
            td.shrink
              .level.has-text-danger-dark(v-if='post.deleted')
                .level-left.deleted
                  .level-item.mr-2: b-icon(icon='delete', size='is-small')
                  .level-item.is-size-7 {{ $t("posts.deleted") }}
              .level.has-text-warning-dark(v-else-if='post.moderated')
                .level-left
                  .level-item.mr-2: b-icon(icon='clock-outline', size='is-small')
                  .level-item.is-size-7 {{ $t("posts.pending") }}
              .level.has-text-success-dark(v-else)
                .level-left
                  .level-item.mr-2: b-icon(
                    icon='check-circle-outline',
                    size='is-small'
                  )
                  .level-item.is-size-7 {{ $t("posts.published") }}

            td.nowrap.has-text-grey
              span.date {{ formatDate(post.created_at) }}

            td
              span
                b-tag.mr-1(v-if='post.private') {{ $t("channels.private") }}
                b-tag.mr-1(v-if='post.audience && post.audiences.length > 0') {{ $tc("channels.audiences", post.audiences.length, { count: post.audiences.length }) }}
                b-tag.mr-1(v-if='post.public') {{ $t("channels.public") }}

            td.content.is-size-7 {{ contentPreview(post.content) }}

            td
              figure.image.is-square.is-32x32
                img.is-rounded(v-if='post.picture', :src='post.picture')
                img.is-rounded(v-else, :src='assets.placeholder')
            td.has-text-right
              .level.is-inline-block: .level-right
                .level-item
                  router-link.button.is-small.is-info.is-light(
                    :to='{ name: "posts.show", params: { channel: post.channel.uuid, post: post.uuid } }'
                  ) {{ $t("global.see_more") }}

      b-pagination(
        v-if='postCount > config.feed.posts.per_page',
        v-model='currentPage',
        :total='postCount',
        :per-page='config.feed.posts.per_page',
        :range-before='2',
        :range-after='2',
        :rounded='true',
        size='is-small'
      )

    template(v-else-if='channels && channels.length == 0')
      Empty(:messageOverride='$t("posts.no_channel")')

    template(v-else-if='posts && posts.length === 0')
      Empty(
        v-if='isPinnedChannel',
        :messageOverride='$t("placeholders.pinned")'
      )
      Empty(
        v-else-if='form.filters.status !== undefined',
        :messageOverride='$t("placeholders.posts_filters")'
      )
      Empty(v-else, :messageOverride='$t("placeholders.posts")')

    template(v-else)
      table.table.is-fullwidth
        tr(v-for='n in 3')
          td.shrink
            b-skeleton(width='200px')
            b-skeleton(width='200px', size='is-small')
          td: b-skeleton(width='400px')
          td: b-skeleton(width='60px', position='is-right', size='is-large')
</template>

<script>
import Placeholder from '@/images/placeholder-image.png';

import { mapState } from 'vuex';

import ChannelsService from '@/services/ChannelsService';
import PostsService from '@/services/PostsService';

import Main from '@/components/Main.vue';
import Sidebar from '@/components/posts/Sidebar.vue';
import Empty from '@/components/partials/Empty.vue';
import ListFilter from '@/components/partials/ListFilter.vue';

export default {
  components: {
    Main,
    Sidebar,
    Empty,
    ListFilter,
  },

  data: () => ({
    assets: {
      placeholder: Placeholder,
    },
    form: {
      filters: {
        status: undefined,
      },
    },
    isLoaded: false,
    channels: undefined,
    selectedChannel: undefined,
    posts: undefined,
    moderated: undefined,
    recentlyPublished: undefined,
    postCount: undefined,
    currentPage: 1,
  }),

  computed: {
    filters() {
      return {
        status: [
          { slug: 'all', label: this.$t('posts.filters.status.options.all') },
          { slug: 'published', label: this.$t('posts.filters.status.options.published') },
          { slug: 'pending', label: this.$t('posts.filters.status.options.pending') },
          { slug: 'deleted', label: this.$t('posts.filters.status.options.deleted') },
        ],
      };
    },

    isPinnedChannel() {
      return this.selectedChannel && this.selectedChannel.uuid === 'pinned';
    },

    ...mapState({
      config: 'config',
    }),
  },

  async mounted() {
    ChannelsService.all().then((channels) => {
      this.isLoaded = true;
      this.channels = channels.data;
      this.selectDefaultChannel(this.$route.params.channel);

      if (this.channels.length === 0) {
        this.$store.commit('setTitle', { title: 'head.posts_no_channel' });
      }
    });

    if (this.$root.isModerator()) {
      PostsService.moderated().then((posts) => {
        this.moderated = posts;
      });

      PostsService.recentlyPublished().then((posts) => {
        this.recentlyPublished = posts;
      });
    }

    if (this.$route.query.page) {
      const page = parseInt(this.$route.query.page, 10);

      if (!Number.isNaN(page)) {
        this.currentPage = page;
      } else {
        this.currentPage = 1;
        this.$router.push({ query: { page: 1 } });
      }
    } else {
      this.currentPage = 1;
    }
  },

  watch: {
    '$route.params.channel': function channelWatcher(value) {
      this.selectDefaultChannel(value);
    },

    currentPage(page) {
      if (this.selectedChannel && this.$route.params.channel === this.selectedChannel.uuid && parseInt(this.$route.query.page, 10) !== this.currentPage) {
        this.$router.push({ query: { page } });
      }

      this.loadPage();
    },

    'form.filters': {
      handler() {
        this.currentPage = 1;
        this.loadPage();
      },
      deep: true,
    },
  },

  methods: {
    channelSelected(channel) {
      this.posts = undefined;
      this.selectedChannel = channel;
      this.currentPage = 1;
    },

    selectDefaultChannel(channel) {
      if (channel) {
        if (channel === 'pinned') {
          this.selectedChannel = { uuid: 'pinned' };
        } else {
          this.selectedChannel = this.channels.find((c) => c.uuid === channel);
        }
      } else if (this.channels.length > 0) {
        this.$router.keepFlashes = true;
        this.$router.push({
          name: 'posts.list',
          params: { channel: this.channels[0].uuid },
        });
      }

      if (this.selectedChannel) {
        if (channel === 'pinned') {
          this.$store.commit('setTitle', { title: 'head.posts_pinned' });
        } else {
          this.$store.commit('setTitle', { title: 'head.posts', params: { channel: this.selectedChannel.name } });
        }

        this.form.filters.status = undefined;
        this.loadPage();
      }
    },

    loadPage() {
      if (this.selectedChannel) {
        this.posts = undefined;

        PostsService.list(this.selectedChannel.uuid, this.currentPage, this.form.filters).then((posts) => {
          this.posts = posts.data;
          this.postCount = posts.count;
        });
      }
    },

    contentPreview(content) {
      const doc = new DOMParser().parseFromString(content, 'text/html');
      const text = doc.body.textContent || '';

      if (text.length > 100) {
        return `${text.substring(0, 100)}...`;
      }
      return text;
    },
  },
};
</script>

<style lang="scss">
.nowrap {
  white-space: nowrap;
}

tr.deleted {
  * {
    color: #787878;
  }
}

td {
  span.date {
    font-size: 0.9rem;
  }
}
</style>
