import axios from 'axios';
import store from '@/store';

export default {
  async userinfo() {
    return axios.get('/api/v1/id/userinfo')
      .then((response) => store.commit('setPrincipal', response.data))
      .catch((error) => Promise.reject(error));
  },

  async signUp(email, password, passwordConfirmation, name) {
    const body = {
      email,
      password,
      password_confirmation: passwordConfirmation,
      name,
    };

    return axios.post('/api/v1/id/register', body);
  },

  async signIn(email, password) {
    const body = {
      email,
      password,
    };

    return axios.post('/api/v1/id/signin', body);
  },

  async refresh() {
    return axios.post('/api/v1/id/refresh');
  },
};
