<template lang="pug">
Main
  h1.title {{ $t("titles.errors.not_found") }}
  h2.subtitle {{ $t("titles.errors.not_found_subtitle") }}
</template>

<script>
import Main from '@/components/Main.vue';

export default {
  components: {
    Main,
  },

  async mounted() {
    this.$store.commit('setTitle', { title: 'head.not_found' });
  },
};
</script>
