<template lang="pug">
Main
  h1.title {{ $t("titles.moderation") }}
  h2.subtitle(v-if='$route.name == "moderation.list" && posts') {{ $tc("posts.are_pending_moderation", this.posts.length, { count: this.posts.length }) }}
  h2.subtitle(v-else-if='$route.name == "moderation.recent" && posts') {{ $tc("posts.are_recently_published", this.posts.length, { count: this.posts.length }) }}
  h2.subtitle(v-else) &nbsp;

  .tabs.is-toggle.is-toggle-rounded
    ul.is-justify-content-center
      router-link(
        tag='li',
        :to='{ name: "moderation.list" }',
        active-class='is-active',
        exact-path
      )
        a
          b-icon(size='is-small', icon='clock-outline')
          span {{ $t("posts.pending_moderation") }}
      router-link(
        tag='li',
        :to='{ name: "moderation.recent" }',
        active-class='is-active',
        exact-path
      )
        a
          b-icon(size='is-small', icon='subdirectory-arrow-right')
          span {{ $t("posts.recently_published") }}

  template(v-if='posts')
    .box(v-if='posts.length > 0')
      table.table.is-fullwidth
        thead
          tr
            th.shrink
            th.shrink {{ $t("posts.channel") }}
            th.shrink {{ $t("posts.date") }}
            th {{ $t("posts.author") }}
            th.shrink

        tbody
          tr(v-for='post in posts')
            td
              figure.image.is-square.is-32x32
                img.is-rounded(v-if='post.picture', :src='post.picture')
                img.is-rounded(v-else, :src='assets.placeholder')
            td
              | {{ post.channel.name }}
            td.nowrap
              span.date.has-text-grey {{ formatDate(post.created_at) }}
            td.nowrap(v-if='post.author') {{ post.author.name }}
            td.nowrap(v-else): b-tag(type='is-danger is-light') {{ $t("posts.deleted_user") }}
            td.has-text-right
              .level.is-inline-block: .level-right
                .level-item(v-if='$route.name === "moderation.list"')
                  span.has-text-grey.is-size-7 {{ $t("posts.published_in", { distance: formatHuman(post.moderated_until) }) }}
                .level-item
                  router-link.button.is-small.is-info.is-light(
                    :to='{ name: "posts.show", params: { channel: post.channel.uuid, post: post.uuid } }'
                  ) {{ $t("global.see_more") }}

      b-pagination(
        v-if='postCount > config.feed.posts.per_page',
        v-model='currentPage',
        :total='postCount',
        :per-page='config.feed.posts.per_page',
        :range-before='2',
        :range-after='2',
        :rounded='true',
        size='is-small'
      )

    template(v-else)
      Empty(
        v-if='$route.name === "moderation.list"',
        :messageOverride='$t("placeholders.pending")'
      )
      Empty(v-else, :messageOverride='$t("placeholders.published")')

  table.table.is-fullwidth(v-else)
    tbody
      tr(v-for='n in 3')
        td.shrink
          b-skeleton(width='200px')
          b-skeleton(width='200px', size='is-small')
        td
          b-skeleton(width='200px')
          b-skeleton(width='200px', size='is-small')
        td: b-skeleton(width='200px')
        td: b-skeleton(width='60px', position='is-right', size='is-large')
</template>

<script>
import { mapState } from 'vuex';
import PostsService from '@/services/PostsService';

import Placeholder from '@/images/placeholder-image.png';
import Empty from '@/components/partials/Empty.vue';
import Main from '@/components/Main.vue';

export default {
  components: {
    Main,
    Placeholder,
    Empty,
  },

  data: () => ({
    assets: {
      placeholder: Placeholder,
    },
    posts: undefined,
    postCount: undefined,
    currentPage: 1,
  }),

  computed: mapState({
    config: 'config',
  }),

  watch: {
    $route: function routeWatcher() {
      this.posts = undefined;
      this.refresh();
    },

    currentPage(page) {
      if (parseInt(this.$route.query.page, 10) !== this.currentPage) {
        this.$router.push({ query: { page } });
      }

      this.refresh();
    },
  },

  async mounted() {
    this.$store.commit('setTitle', { title: 'head.moderation' });

    if (this.$route.query.page) {
      const page = parseInt(this.$route.query.page, 10);

      if (!Number.isNaN(page)) {
        this.currentPage = parseInt(this.$route.query.page, 10);
      } else {
        this.currentPage = 1;
        this.$router.push({ query: { page: 1 } });
      }
    } else {
      this.currentPage = 1;
    }

    this.refresh();
  },

  methods: {
    refresh() {
      if (this.$route.name === 'moderation.list') {
        PostsService.moderated(this.currentPage).then((posts) => {
          this.posts = posts.data;
          this.postCount = posts.count;
        });
      }

      if (this.$route.name === 'moderation.recent') {
        PostsService.recent(this.currentPage).then((posts) => {
          this.posts = posts.data;
          this.postCount = posts.count;
        });
      }
    },

    contentPreview(content) {
      const doc = new DOMParser().parseFromString(content, 'text/html');
      const text = doc.body.textContent || '';

      if (text.length > 100) {
        return `${text.substring(0, 100)}...`;
      }
      return text;
    },
  },
};
</script>

<style lang="scss" scoped>
td {
  span.date {
    font-size: 0.9rem;
  }
}
</style>
