import axios from 'axios';

export default {
  async all() {
    return axios.get('/api/v1/channels?all=1').then((response) => response.data);
  },

  async list(page, terms, filters) {
    const query = [];

    if (terms !== undefined) {
      query.push(`q=${encodeURI(terms)}`);
    }

    if (filters !== undefined) {
      Object.entries(filters).forEach(([key, entry]) => {
        if (entry) {
          const { slug } = entry;

          if (slug !== undefined) {
            query.push(`${key}=${slug}`);
          }
        }
      });
    }

    return axios.get(`/api/v1/channels?page=${page}&${query.join('&')}`).then((response) => response.data);
  },

  async create(name, visibilities, audiences, picture, moderated, writers, moderators) {
    const body = {
      name,
      visibilities,
      audiences,
      moderated,
      users: {
        writers,
        moderators,
      },
    };

    const data = new FormData();

    data.append('data', JSON.stringify(body));

    if (picture !== undefined) {
      data.append('picture', picture);
    }

    return axios.post('/api/v1/channels', data);
  },

  async get(uuid) {
    return axios.get(`/api/v1/channels/${uuid}`).then((response) => response.data);
  },

  async update(uuid, name, visibilities, audiences, picture, removePicture, moderated, writers, moderators) {
    const body = {
      name,
      visibilities,
      audiences,
      remove_picture: removePicture,
      moderated,
      users: {
        writers,
        moderators,
      },
    };

    const data = new FormData();

    data.append('data', JSON.stringify(body));

    if (picture !== undefined) {
      data.append('picture', picture);
    }

    return axios.put(`/api/v1/channels/${uuid}`, data);
  },

  async delete(uuid) {
    return axios.delete(`/api/v1/channels/${uuid}`);
  },
};
