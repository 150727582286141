import axios from 'axios';

import { setupIntercom } from '@/ext/intercom';

export default {
  async config(store) {
    return axios.get('/api/v1/-/config')
      .then((response) => {
        store.commit('setConfig', response.data);

        return Promise.resolve(response.data);
      })
      .catch((error) => Promise.reject(error));
  },

  async intercomConfig() {
    return axios
      .get('/api/v1/-/config/intercom')
      .then((response) => {
        setupIntercom(response.data);

        return Promise.resolve(response.data);
      })
      .catch((error) => Promise.reject(error));
  },
};
