<template lang="pug">
#vue
  div(v-if='config')
    b-navbar.has-shadow
      template(#brand)
        b-navbar-item(tag='router-link', :to='{ path: "/" }')
          img(:src='assets.logo')

      template(#start)
        b-navbar-item(
          v-if='$store.state.principal',
          tag='router-link',
          :to='{ name: "posts.list" }'
        ) {{ $t("titles.posts") }}
        b-navbar-item(
          v-if='$store.state.principal && $root.isAdmin()',
          tag='router-link',
          :to='{ name: "channels.list" }'
        ) {{ $t("titles.channels") }}
        b-navbar-item(
          v-if='$store.state.principal && $root.isAdmin()',
          tag='router-link',
          :to='{ name: "users.list" }'
        ) {{ $t("titles.users") }}
        b-navbar-item(
          v-if='$store.state.principal && $root.isModerator()',
          tag='router-link',
          :to='{ name: "moderation.list" }'
        )
          | {{ $t("titles.moderation") }}
          b-tag.ml-2(
            v-if='moderated > 0',
            type='is-info is-light',
            :rounded='true'
          ) {{ moderated }}

      template(#end)
        b-navbar-item(tag='div')
          .level(v-if='$store.state.principal')
            .is-block.mr-4.level-left.has-text-right
              p {{ $t("global.logged_in_as", { name: $store.state.principal.name }) }}
              p.is-size-7 {{ $store.state.principal.effective_context.name }}
            .level-right
              a.mr-2.button.is-light.is-small.is-info(
                v-if='$root.hasScope("https://accounts.appscho.com/scope/context.change")',
                @click='changeContext'
              ) {{ $t("global.switch_context") }}
              router-link.mr-1.button.is-primary.is-small(
                v-if='!$store.state.principal.admin',
                :to='{ name: "account" }'
              ) {{ $t("titles.account") }}
              a.button.is-light.is-small(@click='signOut') {{ $t("global.signout") }}

    router-view
  div(v-else)
    p.has-text-centered Loading...
</template>

<script>
import { mapState } from 'vuex';
import PostsService from '@/services/PostsService';

import NotFound from '@/components/errors/NotFound.vue';
import Flash from '@/components/partials/Flash.vue';
import AppSchoLogo from '@/images/appscho.png';

export default {
  components: {
    NotFound,
    Flash,
  },

  data: () => ({
    assets: {
      logo: AppSchoLogo,
    },
    moderated: 0,
  }),

  computed: mapState({
    config: 'config',
    title: 'title',
    titleParams: 'titleParams',
  }),

  watch: {
    title() {
      this.setTitle();
    },

    titleParams() {
      this.setTitle();
    },

    '$i18n.locale': function localeWatcher() {
      this.setTitle();
    },

    $route: function routeWatcher() {
      this.refreshCounts();
    },
  },

  async mounted() {
    this.refreshCounts();
  },

  methods: {
    setTitle() {
      const { title } = this.$store.state;
      const params = this.$store.state.titleParams;

      if (title === null) {
        document.title = this.$t('head.base');
      } else {
        document.title = `${this.$t('head.base')} • ${this.$t(title, params)}`;
      }
    },

    refreshCounts() {
      PostsService.moderatedCounts().then((counts) => {
        this.moderated = counts.moderated;
      });
    },

    signOut() {
      this.$store.commit('deletePrincipal');
      this.$flash.add('success', 'flashes.signout_successful');
      this.$router.push({ name: 'sessions.signin' });

      Intercom('shutdown'); // eslint-disable-line no-undef

      window.intercomSettings = undefined;
    },

    changeContext() {
      this.$oidc.signIn();
    },
  },
};
</script>

<style lang="scss">
#main {
  display: flex;
  min-height: calc(100vh - 3.25em);
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;

  img {
    max-height: 3.25em;
  }
}

.navbar-menu {
  .navbar-item {
    border-bottom: 2px solid transparent;

    &.router-link-active {
      border-color: #0499bc;
    }
  }
}
</style>
