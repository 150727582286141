<template lang="pug">
.filter
  label.label {{ title }}
  b-field
    b-input(
      :placeholder='placeholder',
      v-model='value',
      @keyup.native.enter='setInput'
    )
</template>

<script>
export default {
  props: {
    title: String,
    placeholder: String,
  },

  data: () => ({
    value: '',
  }),

  methods: {
    setInput() {
      this.$emit('input', this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  margin-bottom: 32px;

  label {
    font-size: 0.8rem;
  }

  ::v-deep input.input {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    font-size: 0.9rem;
  }
}
</style>
