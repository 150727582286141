<template lang="pug">
#main
  slot(name='sidebar')

  #content
    .container.p-4
      Flash(
        v-if='flashes',
        v-for='flash in flashes',
        :key='flash.id',
        :id='flash.id',
        :variant='flash.variant',
        :message='flash.message'
      )

      slot

    Footer
</template>

<script>
import { mapState } from 'vuex';

import Flash from '@/components/partials/Flash.vue';
import Footer from '@/components/partials/Footer.vue';

export default {
  components: {
    Flash,
    Footer,
  },

  computed: mapState({
    flashes: 'flashes',
  }),
};
</script>

<style lang="scss">
#main {
  display: flex;
  min-height: calc(100vh - 3.25em);
}

#content {
  flex: 1;
}
</style>
