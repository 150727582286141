<template lang="pug">
.filter
  label.label {{ title }}
  CustomSelect(
    v-on='$listeners',
    v-bind='$attrs',
    :options='options',
    :placeholder='placeholder',
    track-by='slug',
    label='label'
  )
</template>

<script>
import CustomSelect from '@/components/partials/CustomSelect.vue';

export default {
  components: {
    CustomSelect,
  },

  props: {
    title: String,
    placeholder: String,
    options: Array,
  },

  methods: {
    setInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  margin-bottom: 32px;

  label {
    font-size: 0.8rem;
  }
}
</style>
