import axios from 'axios';

export default {
  async list(channel, page, filters) {
    const query = [];

    if (filters !== undefined) {
      Object.entries(filters).forEach(([key, entry]) => {
        if (entry) {
          const { slug } = entry;

          if (slug !== undefined) {
            query.push(`${key}=${slug}`);
          }
        }
      });
    }

    return axios.get(`/api/v1/channels/${channel}/posts?page=${page}&${query.join('&')}`).then((response) => response.data);
  },

  async get(post) {
    return axios.get(`/api/v1/posts/${post}`).then((response) => response.data);
  },

  async create(channel, visibilities, audiences, content, picture, pinned) {
    const body = {
      visibilities,
      audiences,
      content,
      pinned,
    };

    const data = new FormData();

    data.append('data', JSON.stringify(body));

    if (picture !== undefined) {
      data.append('picture', picture);
    }

    return axios.post(`/api/v1/channels/${channel}/posts`, data);
  },

  async delete(uuid) {
    return axios.delete(`/api/v1/posts/${uuid}`);
  },

  async pin(uuid) {
    return axios.post(`/api/v1/posts/${uuid}/pin`);
  },

  async unpin(uuid) {
    return axios.post(`/api/v1/posts/${uuid}/unpin`);
  },

  async moderatedCounts() {
    return axios.get('/api/v1/posts/moderated/counts').then((response) => response.data);
  },

  async moderated(page) {
    return axios.get(`/api/v1/posts/moderated?page=${page}`).then((response) => response.data);
  },

  async recent(page) {
    return axios.get(`/api/v1/posts/moderated/recent?page=${page}`).then((response) => response.data);
  },

  async moderatePublish(uuid) {
    return axios.post(`/api/v1/posts/${uuid}/moderation/publish`);
  },

  async moderateDelete(uuid) {
    return axios.post(`/api/v1/posts/${uuid}/moderation/delete`);
  },

  async recentlyPublished() {
    return axios.get('/api/v1/posts/moderated/recent').then((response) => response.data);
  },
};
