export default {
  en: {
    errors: {
      must_be_admin: 'This action requires you to be an administrator of your context',
      must_be_moderator: 'This action requires you to be a moderator of this channel',
      missing_fields: 'Some required fields are missing from the form',
      invalid_image: 'Your image must be a .jpg or .png and weigh under 1 MB',
      sessions: {
        verify_success: 'Your account was successfully created, you can now sign in',
        verify_failed: 'We could not verify your user account, please contact your administrator',
        signin_failed: 'There was an error while signing you in, please check your credentials',
      },
      users: {
        unknown_user: 'The user you requested does not exist',
        invalid_email: 'This email address cannot be used to create an account',
        duplicate_email: 'The provided email address is already used by another user',
        create_error: 'An error occured while trying to create this user',
        update_error: 'An error occured while trying to update this user',
        cannot_delete_account: 'This user account cannot be deleted',
        cannot_change_password: 'This user account cannot have its password changed',
      },
      channels: {
        unknown_channel: 'The channel you requested does not exist',
        missing_permission: 'You are missing a permission to perform this action',
        create_error: 'An error occured while trying to create this channel',
        update_error: 'An error occured while trying to update this channel',
      },
      posts: {
        unknown_post: 'The post your requested does not exist',
        content_too_long: 'The content of the post you are trying to create is too long',
        too_many_pins: 'You have already reached the maximum number of pinned posts for your context',
        cannot_pin_deleted: 'You cannot pin a post that has been deleted',
        cannot_delete_deleted: 'You cannot delete a post that has already been deleted',
      },
    },

    titles: {
      title: 'AppScho • Campus feed',
      menu: 'Menu',
      signup: 'Sign up',
      signin: 'Sign in',
      verify: 'Create account',
      reset: 'Reset password',
      account: 'My account',
      home: 'Home',
      help: 'Help',
      admin: 'Management',
      posts: 'Posts',
      post: 'Post',
      posts_new: 'New post',
      channels: 'Channels',
      channels_new: 'New channel',
      channels_edit: 'Edit channel',
      users: 'Users',
      users_new: 'New user',
      users_edit: 'Edit user',
      moderation: 'Moderation',
      errors: {
        not_found: 'Page not found',
        not_found_subtitle: 'This page does not exist',
      },
    },

    head: {
      base: 'Campus feed',
      not_found: 'Page not found',
      signin: 'Sign in',
      verify: 'Account verification',
      reset: 'Reset your password',
      posts: 'Posts in %{channel}',
      posts_pinned: 'Pinned posts',
      posts_no_channel: 'Welcome to your Campus Feed',
      post: 'Post in %{channel}',
      post_new: 'New post',
      channels: 'Channels',
      channel: 'Channel: %{channel}',
      channel_new: 'New channel',
      users: 'Users',
      user: 'User: %{user}',
      user_new: 'New user',
      moderation: 'Moderation',
    },

    global: {
      signup: 'Sign up',
      signin: 'Sign in',
      signout: 'Sign out',
      logged_in_as: 'Logged in as {name}',
      switch_context: 'Switch context',
      empty: 'There is no content to display',
      delete: 'Delete',
      cancel: 'Cancel',
      see_more: 'Open',
    },

    flashes: {
      signup_successful: 'Your registration was successful, please sign.',
      signup_failed: 'An error occured while trying to sign you up, please try again later.',
      signin_successful: 'You have been signed in successfully.',
      signin_failed: 'There was an error while signing you in, please check your credentials.',
      signout_successful: 'You have been logged out successfully.',
    },

    placeholders: {
      pinned: 'There is no pinned post at the moment.',
      posts: 'No post was published in this channel yet.',
      posts_filters: 'No post matches the selected filters.',
      channels: 'No channel was created yet.',
      channels_filters: 'No channel matches the selected filters.',
      pending: 'There is no post pending moderation.',
      published: 'No post was published without moderation recently.',
    },

    signup: {
      name: 'Name',
      email: 'E-mail address',
      password: 'Password',
      password_confirmation: 'Confirm password',
    },

    signin: {
      asconnect: 'AppScho Connect',
    },

    reset: {
      reset_request_success: 'If this account exists, an email was sent to your with instructions to reset your password',
      reset_success: 'You password was reset successfully, please try and sign in again',
      reset_error: 'An error occured while trying to reset your password',
    },

    verify: {
      subtitle: 'Last step before accessing the campus feed',
    },

    account: {
      change_password: 'Change password',
      change_password_success: 'Your password was changed successfully',
      change_password_error: 'An error occured while trying to change your password',
    },

    home: {
      welcome: 'Welcome to our application',
    },

    help: {
      subtitle: 'You can get help here',
      hello: 'Hello, world!',
    },

    posts: {
      no_channel: 'No channel was created for your institution yet.',
      new: 'New post',
      content: 'Content',
      media: 'Media',
      media_prompt: 'This image must be a .jpg or .png and weigh under 1 MB.',
      delete_picture: 'Delete picture',
      link_title: 'Insert link',
      link_content: 'Please enter a secure (https://) address for this link',
      pin: 'Pin',
      unpin: 'Unpin',
      pinned: 'Pinned',
      pinned_posts: 'Pinned posts',
      published: 'Published',
      deleted: 'Deleted',
      pending: 'Pending',
      deleted_audience: 'deleted',
      create: 'Create post',
      new_success: 'The post was successfully published',
      new_error: 'An error occured during the creation of this post',
      date: 'Date',
      public: 'Public',
      private: 'Private',
      audiences: 'Audiences',
      audiences_prompt: 'Select the audiences that will have access to this post.',
      audience_warning_private: 'Beware that this post will be visible by all authenticated users in your app.',
      audience_warning_public: 'Beware that this post will be visible by all guest users in your app.',
      audience_warning_all: 'Beware that this post will be visible by all users in your app.',
      moderated: 'Pending moderation',
      are_pending_moderation: '%{count} post pending moderation | %{count} posts pending moderation',
      pending_moderation: 'Posts pending moderation',
      are_recently_published: '%{count} post published without moderation | %{count} posts published without moderation',
      recently_published: 'Posts published without moderation',
      moderate: 'This post is pending moderation, if no action is taken, it will be published automatically on %{date}',
      moderate_publish: 'Publish',
      moderate_delete: 'Delete',
      published_in: 'Published in %{distance}',
      channel: 'Channel',
      author: 'Author',
      delete_confirm_title: 'Confirm the deletion?',
      delete_confirm_content: 'Deleting this post will make it inaccessible forever, are you sure you want to delete this post?',
      delete_success: 'The post was deleted successfully.',
      delete_error: 'An error occured during the deletion of this post',
      pin_error: 'An error occured while trying to pin this post',
      unpin_error: 'An error occured while trying to unpin this post',
      moderation_publish_success: 'The post was published successfully.',
      moderation_delete_success: 'The post was deleted successfully.',
      moderation_error: 'An error occured while trying to moderate this post',
      deleted_user: 'Deleted user',
      restrict_audiences: 'Select for which audiences this post will be available...',
      filters: {
        status: {
          title: 'Publication status',
          placeholder: 'Filter posts by status',
          options: {
            all: 'All posts',
            published: 'Published',
            pending: 'Pending moderation',
            deleted: 'Deleted',
          },
        },
      },
    },

    channels: {
      subtitle: 'Your post channels',
      subtitle_new: 'Create a new post channel',
      subtitle_edit: 'Modify an existing channel',
      empty: 'No channel was created for your institution',
      new: 'New channel',
      edit: 'Edit',
      create: 'Create channel',
      update: 'Update channel',
      name: 'Channel name',
      visibilities: 'Visibilities',
      private: 'Authenticated users',
      public: 'Public users',
      audience: 'Custom audiences',
      audiences: '%{count} custom audience | %{count} custom audiences',
      audience_warning_private: 'Beware that any user authorized to write to this channel be able to reach all authenticated users of your app.',
      audience_warning_public: 'Beware that any user authorized to write to this channel be able to reach all guest users of your app.',
      audience_warning_all: 'Beware that any user authorized to write to this channel be able to reach all users of your app.',
      restrict_audiences: 'Restrict which audiences can be posted to...',
      restrict_users: 'Select users allowed to published to this channel...',
      restrict_moderators: 'Select users allowed to moderate this channel...',
      no_audiences: 'No custom audiences have been configured for your institution, you will not be able to narrow down visibility by audience.',
      moderated: 'Moderated channel',
      new_success: 'The channel was created successfully.',
      new_error: 'An error occured during the creation of this channel',
      edit_success: 'The channel was edited successfully.',
      edit_error: 'An error occured during the edition of the channel',
      delete_confirm_title: 'Confirm the deletion?',
      delete_confirm_content: 'Deleting this channel will also delete all the post it contains, are you sure you want to delete this channel?',
      delete_success: 'The channel was deleted successfully.',
      delete_error: 'An error occured during the deletion of this channel',
      permissions: 'Permissions',
      writer: 'Writer',
      writers: 'Writers',
      moderator: 'Moderator',
      moderators: 'Moderators',
      filters: {
        search: 'Search a channel name',
        moderation: {
          title: 'Moderation status',
          placeholder: 'Filter channels by moderation status',
          options: {
            all: 'All channels',
            moderated: 'Moderated',
            unmoderated: 'Unmoderated',
          },
        },
      },
    },

    users: {
      subtitle: 'Authorized users',
      subtitle_new: 'Create a new local user',
      subtitle_edit: 'Modify an existing user',
      firstname: 'First name',
      lastname: 'Last name',
      new: 'New user',
      edit: 'Edit',
      create: 'Create user',
      update: 'Update user',
      email: 'E-mail address',
      name: 'User full name',
      verified: 'Verified user',
      unverified: 'Unverified user',
      admin: 'Administrator',
      channels: 'Channels',
      writer_channels: 'Channels as writer',
      moderator_channels: 'Channels as moderator',
      new_success: 'The user was created successfully.',
      new_error: 'An error occured during the creation of this user',
      edit_success: 'The user was saved successfully.',
      edit_error: 'An error occured during the edition of the user',
      delete_confirm_title: 'Confirm the deletion?',
      delete_confirm_content: 'Deleting this user will prevent them from accessing the platform, are you sure you want delete this user?',
      delete_success: 'The user was deleted successfully.',
      delete_error: 'An error occured during the deletion of this user',
      restrict_publish: 'Select the channels to which this user will be allowed to post...',
      restrict_moderation: 'Select the channels this user will be allowed to moderate...',
      filters: {
        search: 'Search for a user',
      },
    },

    stats: {
      view: 'Views',
      open: 'Clicks',
      like: 'Likes',
    },

    multiselect: {
      enter_to_select: '',
      enter_to_remove: '',
      selected: 'Selected',
      no_result: 'No result was match for this search',
    },
  },
};
